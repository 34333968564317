/* eslint-disable max-lines */
import {Dictionary} from './dictionary';

export default {
	project: 'Проект',

	title: 'Заголовок',
	text: 'Текст',

	moderator: 'Модератор',
	speaker: 'Спикер',

	lightTheme: 'Светлая тема',
	darkTheme: 'Темная тема',

	auth: {
		title: 'Авторизация',
		passwordWarn: 'Неправильный пароль',
		login: 'Логин',
		password: 'Пароль',
	},

	sidebar: {
		rooms: {
			title: 'Комнаты',
			descr: 'Здесь отображается информация обо всех комнатах в сервисе, включая запланированные.',
		},
		allRooms: 'Все комнаты',
		archivedRooms: 'Архив комнат',
		createdRooms: {
			title: 'Запланированные комнаты',
			descr:
				'Запланируйте комнату, которая откроется в указанное время, и посмотрите информацию обо всех будущих чатах.',
		},
		lastMessages: 'Все сообщения',
		reports: {
			title: 'Жалобы',
			descr:
				'Актуальные жалобы пользователей на нарушения правил чата. Модератор может скрыть сообщение, заблокировать пользователя или просто закрыть жалобу. После закрытия жалобы с ней больше нельзя совершать никаких действия, так как она переходит в архив.',
		},
		moderation: 'Модерация',
		archivedReports: {
			title: 'Архив жалоб',
			descr: 'Здесь собрана вся информация о рассмотренных и завершенных жалобах.',
		},
		bans: {
			title: 'Блокировки',
			descr:
				'Список заблокированных пользователей. Любого пользователя можно разблокировать раньше срока, а также проверить всю информацию о блокировке. Нажав на RoomID, перейдите в комнату и прочтите сообщение, после отправки которого пользователя заблокировали.',
		},
		banHistory: {
			title: 'Архив банов',
			descr: 'Список всех завершенных блокировок пользователей.',
		},
		admin: 'Управление админкой',
		roomModers: 'Модераторы комнат',
		wordList: 'Премодерация',
		blackPhraseList: {
			title: 'Стоп-список фраз',
			descr:
				'Слова и фразы, содержащие пробелы, цифры или специальные символы, которые пользователи не смогут отправить в чат. Вы можете добавить новые фразы и удалить неактуальные.',
		},
		blackList: {
			title: 'Стоп-список слов',
			descr:
				'Слова без пробелов и символов, которые пользователи не смогут отправить в чат. Вы можете добавить новые слова и удалить неактуальные.',
		},
		whiteList: {
			title: 'Список валидных слов',
			descr:
				'Слова и фразы, которые частично или полностью совпадают с запрещенными словами, но таковыми не являются. Вы можете добавить новые слова и удалить неактуальные.',
		},
		siteList: {
			title: 'Стоп-список сайтов',
			descr:
				'URL, которые пользователи не смогут отправлять в чат. Также пре-модерация не будет пропускать производные ссылки.',
		},
		correctsList: 'Список замен символов',
		logout: 'Выйти',
		highlights: {
			title: 'Маркетинговая рассылка',
			descr:
				'Отправьте или запланируйте рассылку маркетинговых сообщений в чаты и посмотрите актуальную информацию об уже отправленных.',
		},
		autoBanned: {
			title: 'Автоматические блокировки',
			descr:
				'Список пользователей, заблокированных по причине поступивших на них жалоб. Чтобы пользователь был заблокирован автоматически, на него в течение чата должны пожаловаться шесть других пользователей в одной комнате  или десять пользователей во всех чатах. Модератор может подтвердить или отклонить такую блокировку.',
		},
		pollsQuizzes: {
			title: 'Опросы и квизы',
			descr:
				'Найдите информацию об опубликованных опросах, создайте или запланируйте размещение новых.',
		},
		widgets: 'Виджеты',
		soundQuality: {
			title: 'Оценка качества',
			descr: 'Здесь собраны ответы пользователей на вопросы о качестве проведенного эфира.',
		},
		defaultLists: 'Дефолтные списки',
		settings: 'Настройки',
		admins: {
			title: 'Модераторы и админы сервиса',
			descr:
				'Создайте доступы для новых модераторов или администраторов или отредактируйте уже существующие.',
		},
		report: 'Отчеты',
		appearance: {
			title: 'Кастомизация чатов',
			descr:
				'Загрузите цветовую схему чатов, добавьте иконки, стикеры, аватары, а также выберите какие элементы интерфейса будут доступы для пользователей.',
		},
		language: {
			title: 'Язык и локализация',
			descr: 'Настройте отображение текстов в чате для разных языков.',
		},
		slowMode: {
			title: 'Режим замедления',
			descr:
				'При активации этого режима пользователям, отправившим сообщение в чат, нужно будет подождать, чтобы отправить следующее. ',
		},
		chatGPT: {
			title: 'Чат GPT',
			descr: 'Включите или выключите чат-бота и настройте его так, как необходимо. ',
		},
		toplist: 'Toп лист',
		badges: {
			title: 'Бейджи и ачивки',
			descr: 'Загрузите список пользователей, которым нужно выдать бейджи и ачивки.',
		},
		automoderation: 'AI модерация',
		feedback: 'Обратная связь',
		flaggedMessage: {
			title: 'Панель технической поддержки',
			descr:
				'Здесь собраны сообщения пользователей с упоминанием техническими вопросов или проблем. Вы можете рассмотреть эти запросы, оставить к ним комментарии или отклонить.',
		},
		email: {
			title: 'Электронный адрес для обращений',
			descr:
				'Добавьте имейл-адрес, куда пользователи смогут написать, если у них возникнут вопросы по поводу блокировок.',
		},
		amplitude: 'Amplitude apiKey',
		functionalSettings: 'Функциональные настройки',
		profileSettings: 'Настройки профиля',
		reporting: 'Отчеты',
		export: {
			title: 'Экспорт',
			descr:
				'Выгрузите список пользователей, зашедших в чаты, или отправленные сообщения за период.',
		},
		projects: 'Проекты',
		createProject: 'Создать проект',
		projectsList: 'Список проектов',
		welcome: 'Приветствие',
		analytics: 'Аналитика',
		semantic: 'Семантический анализ',
		documentation: 'Документация',
		autoposting: 'Автопостинг',
		webhooks: 'Вебхуки',
		liveStreams: 'Прямые аудиоэфиры',
		offers: {title: 'Маркетинговый оффер (поп-ап)', descr: ''},
		gambles: {title: 'Публикация выигрышей', descr: ''},
		images: {title: 'Изображения', descr: ''},
		information: {title: 'Информация', descr: ''},
		changelog: {title: 'Changelog', descr: ''},
	},

	room: {
		title: 'Комната',
		status: {
			live: 'В эфире',
			soon: 'Запланирована',
			disabled: 'Временно недоступна',
			ended: 'Завершена',
			title: 'Статус',
		},
		switch: 'Можно говорить',
		autoRecord: 'Автоматическая запись',
		enableSlowMode: 'Включить медленный режим',
		disableSlowMode: 'Выключить медленный режим',
		disableSlowmodeWarning: 'будут применены общие настройки медленного режима',
		slowmodeDelay: 'Таймаут отправки',
		slowmodeSettingsDelay: 'таймаут отправки',
		listenStreamRecording: 'Прослушать запись',
		generateChatLink: 'Сгенерировать ссылку на вход',
		adminNameLabel: 'Ваше имя в чате: ',
	},

	table: {
		externalRoomId: 'Ключ комнаты',
		name: 'Название',
		user: 'Пользователь',
		users: 'Пользователи (онлайн / всего)',
		messages: 'Сообщений (за день / за месяц / всего)',
		status: 'Статус',
		reports: 'Жалобы',
		clear: 'Очистить',
		timeStart: 'Время начала',
		timeEnd: 'Время окончания',
		accuser: 'Обвинитель',
		accused: 'Обвиняемый',
		reason: 'Причина',
		message: 'Сообщение',
		room: 'Комната',
		count: 'Количество',
		timeCreated: 'Время создания',
		reportTime: 'Время создания',
		timeBan: 'Время бана',
		login: 'Логин',
		role: 'Роль',
		changePassword: 'Смена пароля',
		remove: 'Удалить',
		banIn: 'Где',
		banDuration: 'Плановая длительность бана',
		unBanTime: 'Время и дата фактического разбана',
		creator: 'Создал',
		comment: 'Комментарий',
		pic: 'Картинка',
		description: 'Описание',
		autoBan: 'автобан',
		autoReviewed: 'рассмотрена автоматически',
		reviewed: 'Рассмотрел',
		until: 'До',
		sentAt: 'Дата и время отправки',
		rate: 'Оценка',
		issue: 'Проблема',
		connectionInfo: 'Информация о соединении',
		actions: 'Действия',
		event: 'Событие',
		resolveTime: 'Время рассмотрения',
	},

	empty: {
		roomsEmpty: 'Комнаты не найдены',
		archiveEmpty: 'Архив пуст',
		nameEmpty: 'Нет названия',
		reportsEmpty: 'Нет жалоб',
		bannedEmpty: 'Нет забаненных пользователей',
		modersEmprty: 'Нет админов',
		usersEmpty: 'Нет участников',
		chatEmpty: 'Сообщений нет',
		correctsEmpty: 'Нет корректировок',
		wordlistEmpty: 'Пустой список',
		highlightsEmpty: 'Нет рассылок',
		stickersEmpty: 'Нет стикеров',
		pollsEmpty: 'Нет опросов',
		feedbackEmpty: 'Нет оценок',
		avatarsEmpty: 'Нет аватарок',
		iconsEmpty: 'Нет иконок',
		reactionsEmpty: 'Нет реакций',
		empty: 'Пустой список',
	},

	textEnding: ['участник', 'участника', 'участников'],
	textEndingUsers: ['человек', 'человека', 'человек'],
	textEndingMessages: ['сообщение', 'сообщения', 'сообщений'],

	inQueue: 'В очереди',
	throttling: 'Задержка сообщений, мс',
	splitStreams: 'Разделить поток сообщений',
	stream: 'поток',
	all: 'Все',

	and: 'и еще',

	clear: 'Очистить',

	inRoom: 'в комнате',
	inApp: 'в приложении',

	date: 'Дата',

	next: 'Вперед',
	prev: 'Назад',
	olderPosts: 'Старые публикации',
	newerPosts: 'Новые публикации',

	invitedGuest: 'Приглашенный гость',

	newMessages: 'Новые сообщения',

	days: 'дней',
	hours: 'часов',
	forever: 'навсегда',

	sec: 'сек',
	min: 'мин',

	secEnding: ['секунда', 'секунды', 'секунд'],
	hoursEnding: ['час', 'часа', 'часов'],

	breadcrumbs: {
		title: 'Админка',
	},

	sorting: {
		title: 'Cортировка по',
		talkers: 'количество пользователей',
		talkersOnline: 'количество пользователей онлайн',
		externalRoomId: 'ключу',
		name: 'названию',
		msg: 'количество сообщений',
		msgday: 'количество сообщений за последний день',
		msgmonth: 'количество сообщений за последний месяц',
		status: 'статусу',
		reportsCount: 'Количество жалоб',
		time: 'времени создания',
		timeBan: 'времени бана',
		id: 'user id',
		banReason: 'причина бана',
		votersCount: 'количество проголосовавших',
		createdAt: 'времени создания',
		finishedAt: 'времени завершения',
	},

	search: {
		placeholder: 'Поиск по',
		btn: 'Поиск',
		roomId: 'ключу',
		name: 'названию',
		externalRoomId: 'комнате',
		pollTitle: 'заголовку',
		comment: 'комментарию',
		info: 'информации',
		talker: 'имени',
		searchBy: 'Поиск по',
		userId: 'ID',
	},

	alerts: {
		btns: {
			cancel: 'Отмена',
			delete: 'Удалить',
			ok: 'Хорошо',
			yes: 'Да',
			stop: 'Остановить',
			end: 'Завершить',
			endAndShare: 'Завершить и поделиться',
			edit: 'Редактировать',
			reset: 'Сбросить',
			saveDraft: 'Сохранить черновик',
			restore: 'Восстановить',
			startOver: 'Начать с нуля',
			backToEdit: 'Вернуться к редактированию',
		},
		room: (externalRoomId: string) => `Комната ${externalRoomId}`,
		reports: 'Жалобы',
		clearRoom: 'Удалить все сообщения?',
		deleteRoom: 'Удалить комнату?',
		createRoom: 'Создание комнаты',
		closeReport: 'Закрыть жалобу?',
		deleteMessage: 'Удалить сообщение?',
		hideMessage: 'Скрыть сообщение?',
		banTitle: 'Забанить пользователя',
		banUser: (name: string) => `Забанить пользователя ${name}?`,
		banTalker: (name: string) => `Забанить пользователя ${name} в комнате?`,
		deleteUser: (name: string) => `Удалить ${name}?`,
		unBan: 'Разбанить пользователя?',
		modersList: 'Модераторы комнат',
		unMode: 'Успешно размоден!',
		alreadyExist: 'Комната с таким ключом уже существует',
		nameAlreadyExist: 'Пользователь с таким именем уже существует',
		saveChanges: 'Сохранить изменения?',
		unsavedChanges: 'Все несохраненные изменения будут сброшены.',
		pollLeaveWarning: 'Вы уверены, что хотите прервать <br> создание опроса?',
		stopRecord: 'Вы уверены, что хотите остановить запись?',
		recordStateReady: 'Запись будет доступна для скачивания по ссылке в течение 24 часов.',
		roomNotFound: 'Комната не найдена',
		pollEnd: 'Вы действительно хотите <br> завершить опрос?',
		pollDelete: 'Удалить опрос?',
		pollEdit: {
			title: 'Редактировать опрос?',
			subTitle: 'Ответы пользователей будут сброшены',
		},
		pollReset: {
			title: 'Сбросить?',
			subTitle: 'Опрос не будет сохранен',
		},
		restore: 'Восстановить из черновика?',
		restoreText: 'У вас есть рассылка, которая не была отправлена.',
		restoreTextPoll: 'У вас есть опрос, который не был опубликован.',
		publishPoll: 'Опубликовать опрос в чате?',
		publishPollWarning:
			'Перед публикацией этого опроса мы автоматически завершим опубликованныe ранее в комнатах: ',
	},

	toasts: {
		room: (externalRoomId: string) => `Комната ${externalRoomId}`,
		user: (name: string) => `Пользователь ${name}`,
		admin: (name: string) => `Админ ${name}`,
		createRoom: (externalRoomId: string) => `Создание комнаты ${externalRoomId}`,
		createModer: 'Создание модератора',
		roomCreated: (externalRoomId: string) => `Комната ${externalRoomId} успешно создана!`,
		nothingChanged: 'Значение не изменилось',
		error: 'Ошибка при обновлении',
		roomDeleted: 'Комната удалена',
		userBanned: 'Пользователь забанен',
		userUnbanned: 'Пользователь разбанен',
		talkerBanned: 'Пользователь забанен в комнате',
		messagesHidden: 'Сообщения скрыты!',
		messagesShown: 'Сообщения отображены!',
		messageSent: (message: string) =>
			`Сообщение ${message} отправлено в панель технической поддержки`,
		reportClosed: 'Жалоба закрыта',
		changePassword: 'Изменение пароля',
		passwordChanged: 'Пароль изменен!',
		changeRole: 'Изменение роли',
		roleChanged: 'Роль изменена!',
		endRoom: 'Вы точно хотите завершить трансляцию?',
		newRoom: (externalRoomId: string) => `Появилась новая комната ${externalRoomId}.`,
		reloadPage: 'Перезагрузите страницу для отображения новых сообщений',
		banInRoom: 'Забанен в комнате!',
		unBanInRoom: 'Разбанен в комнате!',
		micMuted: 'Микрофон заглушен!',
		micUnmuted: 'Микрофон включен!',
		addToModers: 'Назначен модератором комнаты!',
		removeFromModers: 'Удален из модераторов комнаты!',
		addToSpeakers: 'Назначен спикером!',
		removeFromSpeakers: 'Удален из спикеров!',
		roomChanged: (externalRoomId: string) => `Комната ${externalRoomId}: изменена.`,
		success: 'Успешно!',
		errorTitle: 'Ошибка',
		deleteWord: (word: string) => `${word} успешно удалено!`,
		addWord: (word: string) => `${word} успешно добавлено`,
		correctSaved: 'Корректировка сохранена',
		correctDeleted: 'Корректировка удалена',
		banReasonChanged: 'Комментарий изменен!',
		banReason: 'Причина бана',
		draftCreated: 'Черновик сохранен',
		rulesUpdated: 'Правила обновлены',
		banUpdate: 'Обновление бана',
		pollEnded: 'Опрос завершен',
		pollDeleted: 'Опрос удален',
		wordListUpdated: 'Список обновлен',
		slowmode: 'Медленный режим включен',
		noSlowmode: 'Медленный режим выключен',
		pollCreated: 'Опрос создан',
		userAdded: (userID: string) => `Пользователь ${userID} создан`,
		rightsAssigned: (userID: string) => `Права пользователю ${userID} назначены`,
		deleted: 'Удалено',
	},

	btns: {
		createRoom: 'Создать комнату',
		cancel: 'Отменить',
		add: 'Добавить',
		create: 'Создать',
		save: 'Сохранить',
		dontSave: 'Не сохранять',
		unMode: 'Размодить',
		unBan: ' Разбанить',
		ban: 'Бан в приложении',
		ban12hours: 'Бан в приложении на 12 часов',
		ban7days: 'Бан в приложении на 7 дней',
		banned: 'Забанен в приложении',
		banRoom: 'Бан в комнате',
		banRoom12hours: 'Бан в комнате на 12 часов',
		banRoom7days: 'Бан в комнате на 7 дней',
		bannedRoom: 'Забанен в комнате',
		hide: 'Скрыть',
		hidden: 'Скрыто',
		remove: 'Удалить',
		removed: 'Удалено',
		closeReport: 'Закрыть жалобу',
		addModer: 'Добавить модератора',
		changePassword: 'Изменить пароль',
		switchChat: 'Переключить чат',
		addAvatar: 'Загрузить аватар',
		send: 'Отправить',
		voiceRecord: 'Запись эфира',
		approve: 'Подтвердить',
		saveDraft: 'Сохранить как черновик',
		roomSettings: 'Настройки комнаты',
		login: 'Войти',
		import: 'Импорт',
		change: 'Изменить',
		reset: 'Сбросить',
		canceled: 'Отменено',
		search: 'Найти',
		refresh: 'Обновить',
		paste: 'Вставить',
		test: 'Протестировать',
		close: 'Закрыть',
		repeat: 'Повторить',
		chooseFile: 'Выбрать файл...',
		edit: 'Редактировать',
		block: 'Заблокировать',
		unblock: 'Разблокировать',
		unpin: 'Открепить',
		downloadCsv: 'Скачать csv файлом',
		subscribeToUpdates: 'Подписаться на обновления',
		subscribe: 'Подписаться',
	},

	tooltip: {
		hideMessage: 'Скрыть сообщение',
		messageHidden: 'Сообщение скрыто',
		deleteMessage: 'Удалить сообщение',
		messageDeleted: 'Сообщение удалено',
	},

	modals: {
		createRoom: {
			title: 'Создать комнату',
			externakRoomId: 'Ключ',
			externakRoomIdWarn: 'Введите ключ',
			name: 'Название',
			nameWarn: 'Введите название',
			description: 'Описание',
			descriptionWarn: 'Введите описание',
			dateStart: 'Дата начала',
			dateEnd: 'Дата окончания',
			time: 'Время',
		},
		createModer: {
			title: 'Добавить модератора',
			name: 'Имя',
			nameWarn: 'Введите имя',
			password: 'Пароль',
			passwordWarn: 'Введите пароль',
			moderatorDescr:
				'Доступны разделы «Комнаты», «Все сообщения», «Модерация». Может писать сообщения от лица платформы и блокировать пользователей.',
			adminDescr:
				'Доступны все разделы административной панели. Может вносить изменения во внешний вид чата, редактировать стоп-листы, управлять виджетами.',
		},
		changePassword: {
			title: 'Изменить пароль',
			text: 'Введите новый пароль',
			newPassword: 'Новый пароль',
		},
		roomTalkers: {
			title: 'Участники',
		},
		updateAdmin: {
			title: 'Обновить аватар',
		},
		callRecord: {
			title: 'Запись голосового чата',
			btns: {
				startRecod: 'Начать запись',
				stopRecord: 'Остановить запись',
				refresh: 'Обновить',
			},
			table: {
				id: 'id',
				link: 'Ссылка',
				status: 'Статус',
			},
		},
		addTalker: {
			title: 'Добавить участника',
			subtitle: 'Учаcтник сможет войти в комнату с заранее назначенной ролью.',
			inputPlaceholder: 'Введите ID пользователя',
			roomModer: 'Модератор комнаты',
			speaker: 'Спикер (сможет говорить в аудиочате)',
			btnRole: 'Назначить роль',
			assignRoleSubtitle: 'Участник получит следующие права при входе в комнату',
			btnCreateAndRole: 'Создать и назначить роль',
			noUsers: 'Не удалось найти участника с таким ID',
			noUsersText:
				'Этот пользователь ранее не входил в чаты, мы создадим для него учетную запись. Ему будет доступна назначенная роль при следующем входе в текущую комнату.',
		},

		editedMessageHistory: {
			title: 'История изменений',
		},

		badges: {
			title: 'Управление бейджами',
			giveBadge: 'Добавить бейдж участнику',
			text: 'Найдите пользователя чата по его ID, чтобы выдать ему один из бейджей',
			placeholder: 'Введите ID пользователя',
		},
	},

	chatAdminForm: {
		name: 'Имя',
		message: 'Сообщение',
	},

	badges: {
		userBanned: 'Забанен',
		talkerBanned: 'Забанен в комнате',
		speaker: 'Спикер',
	},

	roles: {
		admin: 'Админ',
		moder: 'Модератор',
	},

	autocorrect: {
		addNew: 'Добавить новую корректировку',
		from: 'Из',
		to: 'В',
	},

	wordlist: {
		version: 'Версия списка',
		addPhrase: 'Добавить фразу',
		addWord: 'Добавить слово',
		addLink: 'Добавить ссылку',
		newPhrase: 'Новая фраза',
		deleteWord: 'Удалить слово',
		deletePhrase: 'Удалить фразу',
		newWord: 'Новое слово',
		newLink: 'Новая ссылка',
		phraseWarn: 'Введите новую фразу',
		wordWarn: 'Введите новое слово',
		linkWarn: 'Введите новую ссылку',
		correctWarn: 'Введите корректировку',
		phrase: 'фразу',
		word: 'слово',
		alreadyExist: 'Это слово уже есть в списке!',
		roomChanged: (externalRoomId: string) => `Комната ${externalRoomId}: изменена.`,
		failedSave: (word: string) =>
			`Не удалось сохранить! Вы добавили слово короче двух символов: ${word}`,
		failedSaveSymbol: (word: string) =>
			`Не удалось сохранить! Вы добавили недопустимый символ: ${word}`,
		confirmAddWord: (word: string) => `Вы действительно хотите добавить: ${word}?`,
		confirmDeleteWord: (word: string) => `Вы действительно хотите удалить: ${word}?`,
		blackListWordsAlert: (watchersAppInstance: boolean) =>
			watchersAppInstance
				? 'Вносите сюда только слова без пробелов.'
				: 'Вносите сюда только слова, которые написаны кириллицей и не содержат посторонних знаков и пробелов.',
		blackListPhrasesAlert:
			'Вносите сюда слова и фразы с пробелами, а также слова, которые содержат любые посторонние символы, включая знаки других алфавитов.',
		blackListWordsWarning: (watchersAppInstance: boolean) =>
			watchersAppInstance
				? 'Данное слово содержит пробелы.'
				: 'Данное слово содержит не только кириллические символы.',
		blackListPhrasesWarning:
			'Данное слово содержит только кириллические символы и отсутствуют пробелы.',
	},

	submenu: {
		hideMessages: 'Скрыть все сообщения пользователя',
		showMessages: 'Отобразить все сообщения пользователя',
		showReactions: 'Просмотреть реакции к сообщению',
		goToRoom: 'Перейти в комнату',
		hideMessage: 'Скрыть',
		showMessage: 'Отобразить',
		mode: 'Замодерить',
		unMode: 'Размодерить',
		mute: 'Заглушить микрофон',
		unMute: 'Включить микрофон',
		unBan: 'Разбанить в приложении',
		ban: 'Забанить в приложении',
		hours: 'ч',
		days: 'д',
		unBanInRoom: 'Разбанить в комнате',
		banInRoom: 'Забанить в комнате',
		allUserMessages: 'Все сообщения пользователя',
		flagMessage: 'Отправить в техподдержку',
		editMessage: 'Редактировать сообщение',
		deleteMessage: 'Удалить сообщение',
		pinMessage: 'Закрепить сообщение',
		goToThread: 'Перейти в тред',
	},
	highlights: {
		create: 'Создание рассылки',
		edit: 'Редактировать',
		title: 'Заголовок',
		text: 'Текст',
		linkText: 'Текст в кнопке',
		link: 'Ссылка',
		image: 'Изображение хайлайта',
		iconWeight: (size: number) => `Вес не больше ${size}, `,
		iconMinSize: (pngSize: number, svgSize: number) =>
			`минимальный размер:  PNG — ${pngSize}×${pngSize} px, SVG — ${svgSize}×${svgSize} px.`,
		name: 'Название',
		status: 'Статус',
		rooms: 'Количество комнат',
		views: 'Просмотры',
		actions: 'Действия',
		sendDate: 'Дата отправки',
		planned: 'Запланировано',
		sent: 'Отправлено',
		templates: 'Выбрать шаблон',
		custom: 'Создать свою рассылку',
		history: 'История рассылок',
		learnMore: 'Узнать подробности',
		sendDateTime: 'Дата и время отправки',
		sendNow: 'Отправить прямо сейчас',
		chooseDate: 'Выбрать дату и время',
		endDateTime: 'Дата и время окончания отображения',
		duration: 'Длительность отображения',
		durationEndless: 'Отображать бесконечно',
		setDuration: 'Установить длительность отображения',
		setEndDate: 'Когда остановить показ',
		manualRoomList: 'Добавить комнаты вручную',
		manual: 'Добавить вручную',
		downloadRoomList: 'Загрузить список',
		confirm: 'Подтвердить отправку?',
		cancel: 'Отменить',
		success: 'Рассылка отправлена!',
		roomsError: 'В списке комнат есть как минимум одна несуществующая',
		noExistingRooms: 'В списке нет существующих комнат',
		settings: 'Настройки хайлайта',
		prevStep: 'Предыдущий шаг',
		nextStep: 'Следующий шаг',
		hours: 'Часы',
		minutes: 'Минуты',
		seconds: 'Секунды',
		sec: 'сек',
		specialOffer: 'Специальное предложение',
		specialOfferText:
			'Интерпретация всех изложенных ниже наблюдений предполагает, что еще до начала измерений течение среды восстанавливает электрон.',
		specialOfferTextShort: 'Рекламная рассылка с вашим изображением на фоне',
		availableRooms: 'Рассылка отправлена в комнаты:',
		unavailableRooms: 'Комнаты, недоступные для отправки:',
		goToPrevious: 'Перейти в историю рассылок',
		selectTemplate: 'Выбор шаблона',
		selectTime: 'Время и длительность показа',
		selectRooms: 'Список комнат для рассылки',
		maxRooms: 'Можно ввести максимум 100 комнат',
		downloadFile: 'Выгрузить список комнат',
		draft: ' Сохранить как черновик',
		textType: 'Текстовый шаблон',
		imageType: 'Шаблон с картинкой',
		alignment: 'Выравнивание',
		bgColor: 'Цвет фона',
		btnColor: 'Цвет кнопки',
		bgSolid: 'Сплошной',
		bgGradient: 'Градиент',
		bgGradientStart: 'Начало',
		bgGradientEnd: 'Конец',
		bgGradientDirection: 'Направление',
	},
	offers: {
		template: 'Шаблон',
		content: 'Контент',
		settings: 'Настройки публикации',
		rooms: 'Аудитория',
		create: 'Создать',
		edit: 'Редактировать',
		template0: 'Картинка с затемнением',
		template1: 'Картинка с текстом на плашке',
		template2: 'Картинка над текстом',
		template11: 'Только картинка',
		template10: 'Только текст',
		title: 'Заголовок',
		text: 'Текст',
		startTime: 'Дата начала рассылки',
		endTime: 'Дата окончания рассылки',
		period: {
			title: 'Период отображения',
			text: 'Старт и окончание рассылки ',
		},
		from: 'С',
		to: 'По',
		sendMessage: 'Отправить поп-ап',
		sendMessageDescr:
			'Кто получит поп-ап: все пользователи, участники определенных комнат или отдельные пользователи (персональный поп-ап)?',
		roomList: 'Список комнат',
		roomListDescr: 'RoomID через запятую',
		userList: 'Список пользователей',
		userListDescr: 'UserID через запятую',
		fileRoomDescr: 'Загрузите CVS-файл со списком комнат для отправки поп-апа, формат — UTF-8.',
		fileUserDescr:
			'Загрузите CVS-файл со списком пользователей для отправки поп-апа, формат — UTF-8.',
		radio: {
			allRooms: 'Во все комнаты',
			roomList: 'В комнаты по списку',
			userList: 'Отдельным пользователям',
		},
		availableRooms: 'Рассылка отправлена в комнаты:',
		unavailableRooms: 'Комнаты, недоступные для отправки:',
		availableUsers: 'Рассылка отправлена пользователям:',
		unavailableUsers: 'Пользователи, которым невозможно отправить поп-ап:',
		contentSettings: {
			title: 'Настройки поп-апа',
			picture: 'Изображение',
			addTitle: 'Добавьте заголовок',
			addText: 'Введите текст',
			multipleTimes: 'Сколько раз показать поп-ап пользователю?',
		},
	},

	imageReqs: {
		imageWeight: (size: number) => `Вес не больше ${size}, `,
		imageMaxSize: ([width, height]: number[]) => `размер не более ${width}×${height} точек`,
		imageMinSize: ([width, height]: number[]) => `минимальный размер ${width}×${height} точек`,
		imageMinSizeStikers: ([width, height]: number[]) => `не менее ${width}×${height} точек`,
		formats: (formats: string) => `Допустимые форматы: ${formats}`,
		imageReqs: 'Требования к картинке',
	},

	banReasons: {
		insults: 'Оскорбления, угрозы, ругань',
		personal: 'Персональные данные',
		flood: 'Флуд',
		links: 'Ссылки на сторонние сервисы',
		violence: 'Угрозы насилия',
		fraud: 'Попытки мошенничества и попрошайничество',
		nickname: 'Недопустимый никнейм',
		political: 'Политические, сексуализированные и другие провокации',
		repeated: 'Повторное нарушение',
		auto: 'Жалобы пользователей чата',
		spam: 'Спам',
	},

	tolokaReasons: {
		insults: 'Оскорбления, угрозы, ругань',
		personal: 'Персональные данные',
		flood: 'Флуд',
		links: 'Ссылки на сторонние сервисы',
		violence: 'Угрозы насилия',
		fraud: 'Попытки мошенничества и попрошайничество',
		political: 'Политические, сексуализированные и другие провокации',
		spam: 'Спам',
		suspect: 'Сообщение на грани',
		valid: 'Валидное сообщений',
	},

	reportsReasons: {
		violence: 'Оскорбления, угрозы, ругань',
		provocations: 'Провокации',
		personalDetails: 'Персональные данные',
		flood: 'Флуд',
		spam: 'Спам',
		fraudBegging: 'Мошенничество, попрошайничество',
		other: 'Другое',
	},

	stickers: {
		add: 'Добавить стикер',
		delete: 'Удалить стикер из стикерпака?',
		deleted: 'Стикер удален',
		added: 'Стикер добавлен',
		fileWarning: (name: string) => `Файл "${name}" не соответствует требованиям `,
		sticker: 'Стикер',
		stickerRemoved: 'Стикер удален',
		disclaimer:
			'Загружая на платформу материалы (стикеры), вы подтверждаете, что обладаете всеми правами на них (в том числе на использование изображений и обозначений), и соблюдаете требования применимого законодательства. Размещение нецензурного контента и информации, нарушающей закон или права третьих лиц, не допускается.',
		stickerPack: 'Стикерпак',
		newStickerPack: 'Новый стикерпак',
		uploadNewStickerPack: 'Загрузить новый набор стикеров',
		count: ['стикер', 'стикера', 'стикеров'],
		name: 'Название стикерпака',
	},

	polls: {
		room: 'Список комнат',
		title: 'Заголовок',
		status: 'Статус',
		options: 'Ответы',
		creator: 'Создал',
		startTime: 'Время публикации',
		createdAt: 'Время создания',
		votesLength: 'Проголосовало',
		create: 'Создать опрос',
		endTime: 'Время завершения',
		finisher: 'Завершил',
		roomList: 'Все комнаты',
		type: 'Тип',
		results: 'Результаты опроса',
		totalAnswers: 'Всего голосов',
	},
	poll: {
		toggleSubmenu: {
			create: 'Создать опрос',
			edit: 'Редактировать опрос',
			show: 'Показать опрос',
			end: 'Завершить опрос',
			delete: 'Удалить опрос',
		},
		tooltip: {
			btn: 'Голосовать',
		},
		settings: {
			title: 'Настройки',
			titleAdditionally: 'Дополнительно',
			switches: {
				quizMode: 'Режим викторины',
				multipleChoice: 'Выбор нескольких ответов',
				withPhoto: 'Опрос с фотографией',
			},
			alert:
				'Режим викторины предполагает правильные и неправильные варианты ответа. Минимальное количество верных ответов — один, максимальное — все возможные. Опубликовать опрос в режиме викторины без заявленного правильного ответа невозможно.',
		},
		room: {
			inputPlaceholder: 'Введите комнату',
		},
		date: 'Время отправки',
		dateAndDuration: 'Время отправки и длительность отображения',
		autoSendResults: 'Опубликовать результаты по завершению опроса',
		rooms: 'Список комнат для публикации',
		question: {
			title: 'Вопрос',
			inputPlaceholder: 'Введите вопрос',
		},
		message: {
			title: 'Вы проголосовали',
		},
		answers: {
			title: 'Варианты ответа',
			warning: (count: number) => `Вы можете добавить до ${count} ответов`,
		},
		answer: {
			tooltip: 'Выберите правильный ответ',
			inputPlaceholder: 'Ответ',
		},
		results: {
			text: 'Результаты голосования',
			words: ['голос', 'голоса', 'голосов'],
		},

		btns: {
			addQuestion: 'Добавить вопрос',
			addAnswers: 'Добавить ответы',
			addAnswer: 'Добавить ответ',
			publish: 'Опубликовать',
			vote: 'Голосуйте',
			preview: 'Предпросмотр',
		},
		supTitles: {
			typeQuiz: 'Дай верный ответ',
			typeQuizMultiple: 'Отметь верные варианты',
			typePoll: 'Поделись мнением',
			typePollMultiple: 'Можно отметить несколько вариантов',
			default: 'Публичный опрос',
		},
	},
	pollCreating: {
		titles: {
			created: 'Создать опрос',
			typeQuiz: 'Викторина',
			typePoll: 'Несколько ответов',
			default: 'Публичный опрос',
		},
		btns: {
			saveDraft: 'Сохранить как черновик',
		},
		goback: 'Перейти к опросам',
		availableRooms: 'Опрос создан в комнатах:',
	},
	settings: {
		slowMode: 'Медленный режим',
		greeting: {
			title: 'Плейсхолдер для пустого чата',
			description:
				'Введите сообщение, которое будет отображаться в пустом чате, и не забудьте локализовать его для всех добавленных языков.',
		},
		chatbot: 'Настройки ChatGPT',
		enable: 'Вкл',
		on: 'Вкл',
		off: 'Выкл',
		colors: 'Цветовая схема',
		lang: 'Язык',
		icons: 'Иконки',
		avatars: 'Аватарки',
		reactions: 'Реакции',
		font: 'Шрифт',
		import: 'Импорт/Экспорт',
		reset: 'Сбросить настройки',
		chatRules: {
			title: 'Правила чата',
			descr:
				'Добавьте подробные правила чата для пользователей, и не забудьте локализовать их для всех необходимых языков.',
		},
		stickers: 'Стикеры',
		currentFont: 'Текущий шрифт',
		theme: 'тема',
		controls: 'Элементы интерфейса',
		headerSettings: {
			title: 'Настройки хедера',
			profileSettings: {
				title: 'Персональные настройки',
				description:
					'Настройки пользователя, где можно проверить и поменять аватар и никнейм в любой момент, настроить устройство для участия в аудиоэфире и просмотреть список заблокированных пользователей.',
			},
			userCounter: {
				title: 'Счетчик пользователей',
				description: 'Счетчик, показывающий количество пользователей в чате в реальном времени.',
			},
			addToCalendar: {
				title: 'Кнопка Добавить в календарь',
				description: 'Возможность для пользователя добавить запланированную комнату в календарь.',
			},
			shareButton: {
				title: 'Кнопка Поделиться',

				description: 'Возможность для пользователя поделиться ссылкой на комнату.',
			},
			exitButton: {
				title: 'Кнопка выхода',
				description:
					'Возможность закрыть WebView через кнопку в интерфейсе чата. Кнопка «Выход» отображается в левом верхнем углу. Чтобы она работала, необходимо получать событие, передаваемое через postMessage.',
			},
			enableScreenSharing: {
				title: 'Кнопка Демонстрация экрана',
				description:
					'Включите эту функцию, если вы проводите стримы в чате и ваши спикеры планируют делиться дополнительными материалами с участниками. Спикеры смогут делиться графиками, изображениями или другими визуальными материалами. Поделиться материалами возможно только через веб-версию сервиса. Пользователи смогут видеть предоставленные материалы на всех платформах.',
				warning: 'Включите Прямые аудиоэфиры, чтобы использовать Демонстрацию экрана',
			},
			enableOnlySubscriptionsFilter: {
				title: 'Кнопка Фильтр по подписке',
				description: 'Возможность для пользователя фильтровать сообщения по подпискам',
			},
		},
		showAnimatedEmotions: {
			title: 'Показывать анимированные реакции',
			description:
				'Включите этот переключатель, чтобы дать возможность отправлять анимированные реакции в чат.',
		},
		userSettings: {
			title: 'Пользовательские настройки',
			enableNameChange: {
				title: 'Изменение имени',
				description: 'Возможность менять имя для пользователя.',
			},
			enableAvatarChange: {
				title: 'Изменение аватара',
				description: 'Возможность менять аватар для пользователя.',
			},
			enableNameVerification: {
				title: 'Проверка никнейммов',
				description:
					'Если мы получаем никнеймы из основного профиля пользователя, укажите, нужно ли проверять их через систему пре-модерации.',
			},
			enableProfileDeletion: {
				title: 'Удаление профиля',
				description:
					'Отображение кнопки «Удалить профиль» позволяет удалить профиль в чате, но не влияет на основной профиль пользователя в сервисе.',
			},
		},
		userProfiles: {
			title: 'Профиль пользователя',
			allowUserProfile: {
				title: 'Разрешить пользователям переходить в профиль из чата',
				description: `Пользователи cмогут открывать профили друг друга, выбрав действие в выпадающем меню сообщения или кликнув на аватар. `,
			},
		},
		firstVisitOptions: {
			title: 'Что пользователь видит при первом входе в чат?',
			onboarding: {
				title: 'Онбординг',
				description: `Возможность для пользователя установить никнейм и аватар при первом входе в чат.`,
			},
			chatRulesAccept: {
				title: 'Правила чата при входе',
				description:
					'Короткая версия правил чата, которая показывается пользователю при входе. Там же есть ссылка на полные правила и согласие с политикой конфиденциальности.',
			},
		},
		copybetting: {
			title: 'Копи-беттинг',
			description: `Подключение виджета для копирования ставок в чате. Чтобы воспользоваться этой функциональностью, вам нужны дополнительные настройки. `,
			bettingButton: {
				title: 'Включить копи-беттинг',
			},
		},
		other: {
			title: 'Другое',
		},

		enableFullscreenOption: {
			title: 'Полноэкранный режим чата при просмотре демонстрации экрана',
			descr: '',
		},

		toxicityEstimation: 'Оценка токсичности',
		toxicityEstimationText:
			'Сообщения будут оцениваться на степень токсичности. Оценка видна только модератору.',
		useTolokaAutomoderationToggle: 'Использовать оценку TAI',
		tolokaAutomoderationWarning: 'Использование модели платное, <br>подключить?',
		tolokaAutomoderationWarningText:
			'Использование модели TAI для оценки токсичности и автомодерации оплачивается отдельно. Счет за использование будет выставлен в месяце, следующем за отчетным.',
		tolokaAutomoderationConfirm: 'Включить модель TAI',
		useDBRTAutomoderationToggle: 'Использовать оценку DBRT',
		automoderation: 'Автомодерация TAI',
		autohide: 'Автоскрытие сообщений',
		autohideText:
			'Сообщения пользователей будут скрыты автоматически, если были оценены моделью как токсичные',
		autohideToggle: 'Автоматически скрывать токсичные сообщения',
		preferredModel: 'Предпочитаемая модель',
		preferredModelText: 'Оценку какой модели использовать для скрытия сообщений',
		tolokaRadio: 'TAI',
		DRBTRadio: 'DRBT',
		loader: 'Прелоадер',
		policy: 'Политика конфиденциальности',
		agreement: 'Лицензионное соглашение',
		chatRulesAccept: 'Принятие правил чата',
		tolokaUsage: 'Использование TAI',
		externalPicUrl: 'ImageId url',
		enableUserStatus: 'statusname',
		enableDecrypt: 'decrypt',
		enableRegistrationName: 'nickname',
		enableRegistrationPicId: 'imageId',
		enableRegistrationPicUrl: 'imageUrl',
		defaultTheme: 'Тема по умолчанию',
		enableNameVerification: 'Проверка переданных имён',
		availableLangs: 'Доступные языки',
		availableLangsHint:
			'Выберите язык интерфейса. Если в URL указан GET-параметр «lang», он переопределит настройку языка по умолчанию.',
		enableAgora: 'Включить аудиоэфиры',
		lowerUsersModule: {on: 'Сместить вниз попап участников', off: 'Во весь экран'},
		showDefaultStickers: {
			title: 'Показывать стикеры',
			descr: 'Разрешить пользователям отправлять стикеры в чат',
		},

		userProfile: 'Переход в профиль',
		enableSaveUserIP: 'Cохранять IP-адреса пользователей',
		enableLinksBlock: 'Блокировать отправку ссылок',
		enableMessageTranslation: {
			title: 'Автоматический перевод',
			toggle: 'Включить',
			descr: 'Включить в чате автоматический перевод сообщений для пользователей',
		},
		enableOauth: 'Oauth',
		oauthUrl: 'Oauth url',
		defaultLanguage: {
			title: 'Язык интерфейса по умолчанию',
			descr: 'Язык по умолчанию (используется, если отсутствует GET-параметр «lang»)',
		},
		moderationThresholds: 'Пороги модерации',
		showNameToast: {
			title: 'Показ тоста "Ваше имя в чате"',
			descr:
				'Включите этот переключатель, чтобы отображать тост с сообщением "Ваше имя в чате—%имя_в_чате%" при каждом входе в чат или переходе из комнаты в комнату.',
		},
		enableDomainWhitelist: {title: 'Включить White-list по доменам', descr: ''},
		domainWhitelist: 'Cписок доменов',
		enableMessagePics: {
			title: 'Включить отправку картинок',
			descr:
				'Включите этот переключатель, чтобы пользователи могли отправлять картинки в чат. После активации пользователи смогут загружать и отправлять изображения вместе с текстовыми сообщениями.',
		},
		usersPopup: 'Попап участников',
		enableThreads: {
			title: 'Треды',
			descr:
				'Включите треды в чате, чтобы пользователи могли более эффективно обсуждать темы. Пользователи смогут начинать темы и отвечать в определенных темах в чате.',
		},
		enableAutobans: {
			title: 'Автоматический бан пользователей',
			descr:
				'Пользователи могут быть автоматически забанены, если на них пожаловались больше восьми человек в рамках одной комнаты или больше десяти — в разных комнатах. Модераторы могут отклонить такую блокировку. Автоматически забаненные пользователи получают уведомления о том, что были заблокированы из-за жалоб других пользователей.',
		},
		userModeration: 'Модерация пользователей',
		messaging: 'Сообщения',
		roomStates: {
			title: 'Дополнительные статусы комнат',
			ended: {
				title: 'Текст для завершенной комнаты',
				text: 'Этот текст будет показан пользователям, которые зашли в завершенную комнату (статус комнаты переведен в «Завершена»).',
			},
			soon: {
				title: 'Текст для приостановленной или запланированной комнаты',
				text: 'Этот текст будет показан пользователям, которые зашли в запланированную или приостановленную комнату (статус комнаты переведен в «Скоро» или «Временно недоступна»).',
				windowTitle: 'Заголовок окна',
				countdownSubtitle: 'Заголовок таймера',
			},
		},
		imageModeration: 'Модерация изображений',
		enableMessagePicsModeration: 'Автоматически скрывать нежелательные изображения',
		preModeration: {
			title: 'Предварительная модерация',
			descr:
				'Изображения не будут отображаться в чате, пока не будет завершена модерация контента. Если изображение помечено как ненадлежащее (например, содержащее контент NSFW), оно не будет показано, и пользователь получит соответствующее уведомление.',
		},
		parallelModeration: {
			title: 'Параллельная модерация',
			descr:
				'Изображения отображаются в чате сразу после отправки, и одновременно выполняется модерация контента. Если изображение помечено как ненадлежащее (например, контент NSFW), оно будет скрыто из чата, и пользователь получит соответствующее уведомление.',
		},
		enableWidgetText: {
			title: 'Возможность писать текст при шеринге ставки/выигрыша',
			descr:
				'Включите этот переключатель, чтобы пользователи могли добавлять сообщения, когда они делятся ставками или выигрышами',
		},
		enableContactUsButton: {
			title: 'Кнопка "Написать нам"',
			descr:
				'Включите этот переключатель, чтобы управлять видимостью кнопки «Написать нам» во всплывающем окне, которое отображается для пользователей, заблокированных администратором.',
		},
	},
	uploadBadges: {
		upload: 'Загрузить список',
		loading: 'Загрузка...',
		badge: 'Бейдж',
		title: 'Заголовок',
		description: 'Описание',
		image: 'Изображение',
		uploadText: 'Загрузите файл CVS со списком userID пользователей, разделенных запятой.',
		topX: {
			title: 'Бейджи ТОП-Х',
			description:
				'Пользователям будут рапределены бейджи в соответствии с их положением в списке.',
			text: 'Загрузите файл CVS со списком userID пользователей, разделенных запятой. <br>Порядок пользователей в списке определяет их положение в рейтинге. Формат файла UTF-8.',
		},
		customBadges: {
			title: 'Кастомный бейдж',
			description:
				'Создание новых бейджей. Настройте его название, цвет, описание и загрузите картинки, <br>которые будут показываться в момент его получения',
		},
		downloadExample: 'Скачать пример',
		create: {
			title: 'Создание бейджа',
			settings: 'Настройки и внешний вид',
			users: 'Список пользователей',
			badge: {
				name: 'Название бейджа',
				description:
					'Можно использовать любое количество знаков, но лучше не более 10-12, а также использовать эмоджи.',
			},
			notification: {
				title: 'Уведомление при присвоении бейджа',
				description:
					'Сообщение, которое пользователь увидит при входе в чат, когда ему присвоили этот бейдж',
				toggle: 'Показывать уведомление',
			},
			tooltip: {
				title: 'Тултип',
				description: 'Короткое описание, которое отображается по клику на бейдж',
			},
			file: {
				title: 'Загрузить список из файла',
				text: 'Загрузите файл CVS со списком ID пользователей, которые получат бейдж.',
			},
		},
		edit: 'Редактированиe бейджа',
		usersEnding: ['пользователь', 'пользователя', 'пользователей'],
		backgroundColor: 'Цвет фона',
		textColor: 'Цвет текста',
		assignedBadges: 'Назначенные бейджи',
		giveBadge: 'Выдать бейдж',
	},
	flaggedMessage: {
		reject: 'Отклонить',
		rejected: 'Отклонено',
		considered: 'Рассмотрено',
	},
	profileSettings: {
		profile: 'Профиль',
		title: 'Настройки профиля',
		access: 'Доступ в админку',
		login: 'Логин',
		password: 'Пароль',
		changePassword: 'Изменить пароль',
		userProfile: 'Профиль пользователя',
		userProfileDescr: 'Аватар и имя пользователя, которые отображаются в чате',
		userName: 'Имя в чате',
		uploadAvatar: 'Загрузить аватар',
		currentPassword: 'Текущий пароль',
		newPassword: 'Новый пароль',
		passwordWarn: 'Неверный пароль',
	},

	export: {
		title: 'Экспорт данных',
		type: 'Тип выгрузки',
		from: 'С',
		to: 'По',
		status: 'Статус',
		users: 'Пользователи',
		messages: 'Сообщения',
		ready: 'Готово',
		forming: 'Формируется...',
		new: 'Новая выгрузка',
		hint: 'В сформированном файле будет указано время GMT +0',
		modal: {
			title: 'Экспортировать данные',
			descr: 'Данные для экспорта',
			period: 'Период',
			btnExport: 'Экспортировать',
		},
	},

	semantic: {
		new: 'Новый анализ',
		analysis: 'Анализ',
		model: 'Модель',
		percent: '% выполнения',
		created: 'Создан',
		status: 'Статус',
		settings: 'Настройки',
		prompt: 'Промпт',
		promptDescr: 'Инструкция для AI, которая описывает задание для анализа',
		data: 'Данные для анализа',
		dataDescr:
			'Загрузите файл CSV с данными для семантического анализа. Файл должен содержать только колонку "text", не содержать пустые строки, формат текста UTF-8.',
		top100: 'Top-100 используемых слов',
		test: 'Протестировать',
		start: 'Запустить',
		stop: 'Остановить',
		startAgain: 'Запустить повторно',
		historySave: 'Сохранить в истории',
		promptHistory: 'История промптов',
		savedPrompts: 'Сохраненные промпты',
		downloadResults: 'Скачать результаты',
		analysisStarted: 'Анализ запущен...',
		analysisFinished: 'Анализ завершен',
		words: 'слов',
		promptStatus: {
			inProgress: 'Формируется...',
			finished: 'Готово',
			uploaded: 'Готово',
			draft: 'Черновик',
			error: 'Ошибка',
		},
	},

	projects: {
		name: 'Название проекта',
		created: 'Проект создан',
		new: 'Новый проект',
		settings: 'Настройки',
		removeProject: 'Удалить проект?',
		btn: {
			new: 'Создать новый проект',
		},
	},
	welcome: {
		title: 'Приветственный поп-ап',
		createTitle: 'Создать приветственный поп-ап',
		creating: 'Создание',
		warning: 'Добавьте заголовок или текст',
		warningLink: 'Проверьте ссылку',
		draft: 'Черновик',
		configure: {
			title: 'Открыть окно с чатом',
			text: 'Приветственное сообщение, которое видит пользователь при входе в чат',
		},
		placeholder: {
			title: 'Заголовок сообщения',
			text: 'Текст сообщения в модальном окне',
		},
		btns: {
			configure: 'Настроить',
			edit: 'Редактировать',
			publish: 'Опубликовать',
			saveDraft: 'Сохранить как черновик',
		},
		toggle: {
			on: 'Включено',
		},
		steps: {
			content: {
				stepTitle: 'Содержание',
				title: 'Содержание сообщения',
				welcomeTitle: 'Заголовок окна',
				welcomeText: 'Текст',
				btn: 'Кнопка',
				btnText: 'Текст в кнопке',
				link: 'Ссылка',
			},
			showEntry: {
				title: 'Периодичность показа',
				showRepeat: 'Повторный показ',
				showRepeatDescr: 'Количество показов сообщения',
				showOnce: 'Один раз',
				showTimes: 'Несколько раз',
			},
			frequency: {
				title: 'Частота',
				descr: 'Как часто появляется приветственное сообщение?',
				daily: '1 раз в сутки',
				onEntry: 'При каждом входе пользователя в чат',
			},
			moment: {
				title: 'Момент показа',
				descr: 'Когда пользователь видит сообщение?',
				onEntry: 'В момент входа в чат',
				withDelay: 'С задержкой',
				showDelay: 'Задержка перед показом',
			},
			btns: {
				next: 'Следующий шаг',
				prev: 'Предыдущий шаг',
				publish: 'Опубликовать',
				saveDraft: 'Сохранить как черновик',
			},
		},
		delete: {
			title: 'Удалить?',
			text: 'Вы уверены, что хотите удалить приветственное сообщение?',
		},
	},
	badConnection: 'У пользователя возникли проблемы <br>с подключением',
	message: {
		hidden: 'Скрыто',
		edited: 'Изменено',
	},
	analytics: {
		updateRequestSent: 'Запрос на обновление отправлен',
	},
	autoposting: {
		badge: 'Автопубликация',
		toggle: 'Скрыть автопубликации',
		title: 'Автопостинг сообщений',
		descr:
			'Автоматически публиковать сообщения в комнатах, чтобы поддерживать активность пользователей.',
		toggleLabel: 'Автоматически публиковать сообщения',
		warning:
			'Автоматические сообщения будут по умолчанию скрыты в ленте «Последние сообщения». Чтобы открыть их, вам нужно активировать тоггл под шестеренкой в правой верхнем углу ленты.',
		settings: {
			title: 'Настройки автопостинга',
			tabTitle: 'Настройки',
			descr: 'В каких комнатах будут автоматически публиковаться сообщения?',
			radio: {
				empty: 'Пустые чаты',
				notActive: 'Неактивные чаты',
			},
			selectTitle: 'Считать чат неактивным, если в нем не было сообщений:',
		},
		delay: {
			title: 'Задержка перед публикацией',
			descr:
				'Сколько секунд должно пройти между моментом входа в чат пользователя и автоматической публикацией сообщения?',
		},
		messagesList: {
			title: 'Список используемых сообщений',
			descr: 'Из какого списка будут взяты сообщения для автоматической публикации',
		},
		defaultList: 'Список по умолчанию',
		customList: 'Пользовательский список',
		addLang: 'Добавить локализацию',
		fromFile: {
			title: 'Загрузить сообщения из файла',
			descr: 'Загрузите CSV-файл с сообщениями',
			text: 'Разделите файл на колонки по языкам.',
			warn: 'В первой строке укажите двухзначный код языка (например, RU, EN, ES).',
		},
		fileAlert: {
			title: 'Заменить сообщениями из файла',
			text: 'Вы уверены, что хотите заменить сообщения?',
			btns: {
				replace: 'Заменить',
				cancel: 'Отменить',
			},
		},
		deleteMessagesAlert: {
			title: 'Удалить все сообщения?',
			text: 'Вы уверены, что хотите удалить сообщения? Их будет невозможно восстановить.',
			btns: {
				delete: 'Удалить',
				cancel: 'Отменить',
			},
		},
	},
	webhooks: {
		enable: 'Включить',
		getEvents: 'Получение всех событий',
		errorAlertTitle: 'Не удалось отправить события',
		errorAlertText:
			'Последние 5 вызовов были неуспешны. Проверьте правильность настроек и включите отправку повторно.',
		rooms: 'Комнаты',
	},
	responseStatus: {
		success: 'Успех',
		successText: 'Тестовый запрос успешно отправлен',
		error: 'Ошибка',
		errorText: 'Тестовый запрос вернул ошибку',
	},

	languages: {
		ru: 'Русский',
		en: 'Английский',
		hy: 'Армянский',
		sr: 'Сербский',
		de: 'Немецкий',
		es: 'Испанский',
		ar: 'Арабский',
		ro: 'Румынский',
		tr: 'Турецкий',
		hi: 'Хинди',
		pt: 'Португальский',
		zh: 'Китайсикй',
		ms: 'Малайский',
		bn: 'Бенгальский',
		th: 'Тайский',
		fr: 'Французский',
		fi: 'Финский',
		is: 'Исландский',
		ko: 'Корейский',
		pl: 'Польский',
		az: 'Азербайджанский',
		uz: 'Узбекский',
		uk: 'Украинский',
		ja: 'Японский',
	},
	liveStream: {
		settings: 'Настройки аудиоэфиров',
		autorecording: {
			title: 'Автоматическая запись эфиров',
			text: 'Все аудиоэфиры будут записаны от начала до конца. Вы можете выключить автозапись и включать запись эфиров в отдельных комнатах вручную.',
		},
		qualitySurvey: {
			title: 'Опрос о качестве эфира',
			text: 'Краткий опрос, который позволит пользователям оценить их впечатления от прослушивания аудиоэфиров. Вы сможете использовать полученные данные, чтобы улучшить качество эфиров, а значит, и пользовательский опыт.',
		},
		globalStreamAccess: {
			title: 'Общий доступ к аудиоэфирам',
			text: 'Включить аудиоэфиры по умолчанию   во всех комнатах. Это значит, что прямые аудиоэфиры будут доступны в каждой комнате без необходимости настройки каждой комнаты отдельно.',
		},
	},
	gambles: {
		enableWinPublication: {
			title: 'Включить публикацию выигрышей',
			description:
				'При включенном функционале система будет автоматически публиковать виджеты на основе передаваемых в API данных и заданных настроек.',
		},
		gambleIntervalMS: {
			title: 'Интервал времени, минут',
			description:
				'Установите интервал времени (в минутах) между автоматическими публикациями виджетов. Это позволяет контролировать частоту появления виджетов в чате на основе заданных вами настроек.',
		},
		enableGambleConditions: {
			title: 'Включить разрешениe публикации по условию',
			description:
				'Запрещает автоматическую публикацию виджета, если последнее сообщение в чате уже является виджетом.',
		},
	},
	learnMore: 'Подробнее',
	images: {
		hideImages: 'Скрыть картинки',
	},
	pinnedMessage: 'Закрепленное сообщение',
	reactions: {
		title: 'Реакции к сообщению',
		noReations: 'Реакций никто не оставил',
	},
	toggle: {
		on: 'Включено',
	},
	subscribe: {
		email: 'Email для получения обновлений',
	},
	changelog: {
		category: 'Категория изменений',
		version: 'Версия',
		description: 'Описание изменений',
	},
	linkOpenType: {
		title: 'Cпособ открытия ссылок',
		currentWindow: 'Открыть ссылку в текущем окне',
		newWindow: 'Открыть ссылку в новом окне',
		postMessage: 'Отправить сообщение через postMessage для открытия ссылки в родительском окне.',
	},
} as unknown as Dictionary;

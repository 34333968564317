/* eslint-disable max-lines */

import {Dictionary} from './dictionary';

export default {
	project: 'Project',

	title: 'Title',
	text: 'Text',

	moderator: 'Moderator',
	speaker: 'Speaker',

	lightTheme: 'Light theme',
	darkTheme: 'Dark theme',

	auth: {
		title: 'Authorisation',
		passwordWarn: 'Password is wrong',
		login: 'Login',
		password: 'Password',
	},

	sidebar: {
		rooms: {
			title: 'Rooms',
			descr: 'There is information about all the rooms, including the scheduled ones.',
		},
		allRooms: 'All rooms',
		archivedRooms: 'Rooms archive',
		createdRooms: {
			title: 'Scheduled rooms',
			descr:
				'Plan a room that will open at a particular moment, and find information about all future chats.',
		},
		lastMessages: 'All messages',
		reports: {
			title: 'Reports',
			descr:
				'New users’ reports about breaking the chat rules. Moderators can hide the message, block the user, or complete the report. After completion, the report goes to an archive, so it’s impossible to do something else with it.',
		},
		moderation: 'Moderation',
		archivedReports: {
			title: 'Reports’ archive',
			descr: 'There is all the information about closed reports here.',
		},
		bans: {
			title: 'Bans',
			descr:
				'The list of blocked users. You can unblock users in advance or find all the information about the particular block issue. By clicking RoomID, you get to the room and read the last message of the user before blocking.',
		},
		banHistory: {
			title: 'Bans’ archive',
			descr: 'The list of all ended bans',
		},
		admin: 'Admin panel managing',
		roomModers: 'Room moderators',
		wordList: 'Pre-moderation',
		blackPhraseList: {
			title: 'Block list of phrases',
			descr:
				'Words and phrases containing spaces, numbers, or special characters that users cannot send to chats. You can add new phrases and delete outdated ones.',
		},
		blackList: {
			title: 'Block list of words',
			descr:
				'Words without spaces and special characters users cannot send to chats. You can add new words and delete outdated ones.',
		},
		whiteList: {
			title: 'List of exceptions',
			descr:
				'Words and phrases that partly or entirely match with forbidden words but are appropriate. You can add new phrases and delete outdated ones.',
		},
		siteList: {
			title: 'List of links',
			descr:
				'URLs users are not able to send to chats. Also, derived links are stopped from being sent.',
		},
		correctsList: 'Replacement rules',
		logout: 'Exit',
		highlights: {
			title: 'Marketing messages (coupons)',
			descr:
				'Send or schedule the sending of marketing messages to chats and check all the information about them.',
		},
		autoBanned: {
			title: 'Automatic bans',
			descr:
				'The list of users who were blocked because of other users’ reports. If six users in the room or ten in all rooms report a user, this user will be blocked automatically. Moderators can confirm or decline it.',
		},
		pollsQuizzes: {
			title: 'Polls and quizzes',
			descr: 'Find all the information about published polls and create or schedule a new one.',
		},
		widgets: 'Widgets',
		soundQuality: {
			title: 'Quality control',
			descr: 'Find users’ answers to questions about the quality of completed streams.',
		},
		defaultLists: 'Default blocking list',
		settings: 'Settings',
		admins: {
			title: 'Moderators and administrators',
			descr: 'Create credentials for new moderators or admins and edit the existing ones.',
		},
		report: 'Reports',
		appearance: {
			title: 'Chat customization',
			descr:
				'Upload a colour scheme; add icons, stickers, avatars; choose what interface elements will be available for users.',
		},
		language: {
			title: 'Language and localization',
			descr: 'Choose languages used in chats and translate several interface texts.',
		},
		slowMode: {
			title: 'Slowmode',
			descr: 'After turning this mode on, users should wait to send the next message.',
		},
		chatGPT: {
			title: 'Chat GPT',
			descr: 'Turn chatbot on or off and set it as you need.',
		},
		toplist: 'Top list',
		badges: {
			title: 'Badges and achievements',
			descr: 'Upload the list of users who will get badges and achievements.',
		},
		automoderation: 'AI moderation',
		feedback: 'Feedback',
		flaggedMessage: {
			title: 'Technical Support Requests',
			descr:
				'Find users’ messages with technical issues mentions. It’s possible to complete them, write a comment, or decline.',
		},
		email: {
			title: 'Email',
			descr: 'Add an email address for users’ questions about bans.',
		},
		amplitude: 'Amplitude apiKey',
		functionalSettings: 'Functional settings',
		profileSettings: 'Profile settings',
		reporting: 'Reports',
		export: {
			title: 'Data export',
			descr: 'Download the list of users who joined chats, or messages sent during the period.',
		},
		projects: 'Projects',
		createProject: 'Create a project',
		projectsList: 'Project list',
		welcome: 'Greeting pop-up',
		analytics: 'Analytics',
		semantic: 'Semantic analysis',
		documentation: 'Documentation',
		autoposting: 'Autoposting',
		webhooks: 'Webhooks',
		liveStreams: 'Live streams',
		offers: {title: 'Marketing offer (pop-up)', descr: ''},
		gambles: {title: 'Publication of wins', descr: ''},
		images: {title: 'Images', descr: ''},
		information: {title: 'Information', descr: ''},
		changelog: {title: 'Changelog', descr: ''},
	},

	room: {
		title: 'Room',
		status: {
			live: 'On air',
			soon: 'Soon',
			disabled: 'Unavailable for now',
			ended: 'Ended',
			title: 'Status',
		},
		switch: 'Allow to speak',
		autoRecord: 'Auto record',
		enableSlowMode: 'Enable slow mode',
		disableSlowMode: 'Disable slow mode',
		disableSlowmodeWarning: 'general slow mode settings will be applied',
		slowmodeDelay: 'Timeout',
		slowmodeSettingsDelay: 'timeout',
		listenStreamRecording: 'Listen to recording',
		generateChatLink: 'Generate chat link',
		adminNameLabel: 'Your nickname in the chat: ',
	},

	table: {
		externalRoomId: 'Room ID',
		name: 'Title',
		user: 'User',
		users: 'Users (online / total)',
		messages: 'Messages (per day / per month / total)',
		status: 'State',
		reports: 'Reports',
		clear: 'Clear',
		timeStart: 'Start time',
		timeEnd: 'End time',
		accuser: 'Accuser',
		accused: 'Accused',
		reason: 'Reason',
		message: 'Message',
		room: 'Room',
		count: 'Amount',
		timeCreated: 'Start time',
		reportTime: 'Report time',
		timeBan: `Blocked at`,
		login: 'Login',
		role: 'Role',
		changePassword: 'Change password',
		remove: 'Delete',
		banIn: 'Banned',
		banDuration: 'Ban duration',
		unBanTime: 'Unban time',
		creator: 'Creator',
		comment: 'Comment',
		pic: 'Image',
		description: 'Description',
		autoBan: 'autoban',
		autoReviewed: 'automatically',
		reviewed: 'Reviewer',
		until: 'Until',
		sentAt: 'Sending time',
		rate: 'Rate',
		issue: 'Issue',
		connectionInfo: 'Info',
		actions: 'Actions',
		event: 'Event',
		resolveTime: 'Edit time',
	},

	empty: {
		roomsEmpty: 'No rooms',
		archiveEmpty: 'Archive is empty',
		nameEmpty: 'No name',
		reportsEmpty: 'No reports',
		bannedEmpty: 'No banned users',
		modersEmprty: 'No admins',
		usersEmpty: 'No users',
		chatEmpty: 'No messages',
		correctsEmpty: 'No corrections',
		wordlistEmpty: 'The list is empty',
		highlightsEmpty: 'No highlights',
		stickersEmpty: 'No stickers',
		pollsEmpty: 'No polls',
		feedbackEmpty: 'No feedback',
		avatarsEmpty: 'No avatars',
		iconsEmpty: 'No icons',
		reactionsEmpty: 'No reactions',
		empty: 'The list is empty',
	},

	textEnding: ['user', 'of user', 'of users'],
	textEndingUsers: ['more', 'more', 'more'],
	textEndingMessages: ['message', 'messages', 'messages'],

	inQueue: 'In queue',
	throttling: 'Messages throttling, ms',
	splitStreams: 'Split message stream',
	stream: 'stream',
	all: 'All',

	and: 'and',

	days: 'days',
	hours: 'hours',
	forever: 'permanently',

	sec: 'sec',
	min: 'min',

	secEnding: ['second', 'seconds', 'seconds'],
	hoursEnding: ['hour', 'hours', 'hours'],

	clear: 'clear',

	inRoom: 'room',
	inApp: 'blocked',

	date: 'Date',

	next: 'Next',
	prev: 'Previous',
	olderPosts: 'Older posts',
	newerPosts: 'Newer posts',
	invitedGuest: 'Invited guest',

	newMessages: 'New messages',

	breadcrumbs: {
		title: 'Admin panel',
	},

	sorting: {
		title: 'Sort by',
		talkers: 'amount of users',
		talkersOnline: 'amount of users online',
		externalRoomId: 'Room ID',
		name: 'title',
		msg: 'amount of messages',
		msgday: 'amount of messages per day',
		msgmonth: 'amount of messages per month',
		status: 'state',
		reportsCount: 'Amount of reports',
		time: 'start time',
		timeBan: 'ban time',
		id: 'user id',
		banReason: 'ban reason',
		votersCount: 'amount of voters',
		createdAt: 'created at',
		finishedAt: 'finished at',
	},

	search: {
		placeholder: 'Room ID/name search',
		btn: 'Search',
		roomId: 'Room ID',
		name: 'Room name',
		externalRoomId: 'Room ID',
		pollTitle: 'Poll title',
		comment: 'Comment',
		info: 'Info',
		talker: 'name',
		searchBy: 'Search by',
		userId: 'ID',
	},

	alerts: {
		btns: {
			cancel: 'Cancel',
			delete: 'Delete',
			ok: 'Okay!',
			yes: 'Yes',
			stop: 'Stop',
			end: 'Complete',
			endAndShare: 'Complete and Share',
			edit: 'Edit',
			reset: 'Reset',
			saveDraft: 'Save draft',
			restore: 'Restore',
			startOver: 'Start over',
			backToEdit: 'Back to edit',
		},
		room: (externalRoomId: string) => `Room ${externalRoomId}`,
		reports: 'Reports',
		clearRoom: 'Delete all messages?',
		deleteRoom: 'Delete the room?',
		createRoom: 'Create a room',
		closeReport: 'Close the report?',
		deleteMessage: 'Delete this message?',
		hideMessage: 'Hide this message?',
		banTitle: 'Block user',
		banUser: (name: string) => `Block ${name}?`,
		banTalker: (name: string) => `Block ${name} in the room?`,
		deleteUser: (name: string) => `Delete ${name}?`,
		unBan: 'Unblock user?',
		modersList: 'Room moderators',
		unMode: 'Done!',
		alreadyExist: 'Room with the same key already exists',
		nameAlreadyExist: 'Username already exist',
		saveChanges: 'Do you want to save changes?',
		unsavedChanges: 'All unsaved changes will be lost.',
		pollLeaveWarning: 'Are you sure you want to leave?',
		stopRecord: 'Are you sure you want to stop recording?',
		recordStateReady: 'The record will be available within 24 hours.',
		roomNotFound: 'Room does not exist',
		pollEnd: 'Do you want to complete the survey?',
		pollDelete: 'Delete the poll?',
		pollEdit: {
			title: 'Edit the poll?',
			subTitle: 'User responses will be reset',
		},
		pollReset: {
			title: 'Reset?',
			subTitle: 'The poll will not be saved',
		},
		restore: 'Do you want to restore from a draft?',
		restoreText: 'Your last ad was not sent.',
		restoreTextPoll: 'Your last poll was not publish.',
		publishPoll: 'Do you want to publish a poll in the chat?',
		publishPollWarning: `Polls will be automatically ended in the following rooms:`,
	},

	toasts: {
		room: (externalRoomId: string) => `Room ${externalRoomId}`,
		user: (name: string) => `User ${name}`,
		admin: (name: string) => `Admin ${name}`,
		createRoom: (externalRoomId: string) => `To create a room ${externalRoomId}`,
		createModer: 'New moderator',
		roomCreated: (externalRoomId: string) => `Room ${externalRoomId} is created!`,
		nothingChanged: 'Nothing was changed',
		error: 'Update error',
		roomDeleted: 'Room is deleted',
		userBanned: 'User is blocked',
		userUnbanned: 'User is unblocked',
		talkerBanned: 'User is blocked in a room',
		messagesHidden: 'Messages are hidden!',
		messagesShown: 'Messages are shown',
		messageSent: (message: string) => `Message ${message} sent to technical support dashboard`,
		reportClosed: 'Report is closed',
		changePassword: 'Password changing',
		passwordChanged: 'Password is changed',
		changeRole: 'Role changing',
		roleChanged: 'Role is changed',
		endRoom: 'Do you want to finish a streaming?',
		newRoom: (externalRoomId: string) => `A new room was created ${externalRoomId}.`,
		reloadPage: 'Reload a page to see new messages',
		banInRoom: 'Blocked in the room',
		unBanInRoom: 'Unblocked in the room',
		micMuted: 'Microphone on!',
		micUnmuted: 'Microphone off!',
		addToModers: 'Turned into a moderator!',
		removeFromModers: 'Demoted!',
		addToSpeakers: 'Became a speaker!',
		removeFromSpeakers: 'Demoted!',
		roomChanged: (externalRoomId: string) => `Room ${externalRoomId}: is changed.`,
		success: 'Successfully!',
		errorTitle: 'Error',
		deleteWord: (word: string) => `${word} deleted!`,
		addWord: (word: string) => `${word} added!`,
		correctSaved: 'Correction saved',
		correctDeleted: 'Correction deleted',
		banReasonChanged: 'Text changed!',
		banReason: 'Ban reason',
		draftCreated: 'Saved as draft',
		rulesUpdated: 'Rules updated',
		banUpdate: 'Ban update',
		pollEnded: 'Poll completed',
		pollDeleted: 'Poll deleted',
		wordListUpdated: 'Wordlist updated',
		slowmode: 'Slowmode on',
		noSlowmode: 'Slowmode off',
		pollCreated: 'Poll created',
		userAdded: (userID: string) => `User ${userID} created`,
		rightsAssigned: (userID: string) => `User ${userID} rights assigned`,
		deleted: 'Deleted',
	},

	btns: {
		createRoom: 'Create a room',
		cancel: 'Cancel',
		add: 'Add',
		create: 'Create',
		save: 'Save',
		dontSave: 'Do not save',
		unMode: 'Demoted',
		unBan: ' Unblock',
		ban: 'Block',
		ban12hours: 'Block for 12 hours',
		ban7days: 'Block for 7 days',
		banned: 'Blocked',
		banRoom: 'Block in a room',
		banRoom12hours: 'Block in room for 12 hours',
		banRoom7days: 'Block in room for 7 days',
		bannedRoom: 'Blocked in room',
		hide: 'Hide',
		hidden: 'Hidden',
		remove: 'Delete',
		removed: 'Deleted',
		closeReport: 'Close report',
		addModer: 'Add new moderator',
		changePassword: 'Change password',
		switchChat: 'Switch chat',
		addAvatar: 'Add userpic',
		send: 'Send',
		voiceRecord: 'Record a voice chat',
		approve: 'Approve',
		saveDraft: 'Save draft',
		roomSettings: 'Room settings',
		login: 'Log in',
		import: 'Import',
		change: 'Change',
		reset: 'Reset',
		canceled: 'Canceled',
		search: 'Search',
		refresh: 'Refresh',
		paste: 'Paste',
		test: 'Test',
		close: 'Close',
		repeat: 'Test again',
		chooseFile: 'Choose file...',
		edit: 'Edit',
		block: 'Block',
		unblock: 'Unblock',
		unpin: 'Unpin',
		downloadCsv: 'Download as CSV',
		subscribeToUpdates: 'Subscribe to updates',
		subscribe: 'Subscribe',
	},

	tooltip: {
		hideMessage: 'Hide a message',
		messageHidden: 'Message is hidden',
		deleteMessage: 'Delete message',
		messageDeleted: 'Message is deleted',
	},

	modals: {
		createRoom: {
			title: 'Create a room',
			externakRoomId: 'Room ID',
			externakRoomIdWarn: 'Enter Room ID',
			name: 'Title',
			nameWarn: 'Enter a title',
			description: 'Description',
			descriptionWarn: 'Enter description',
			dateStart: 'Start date',
			dateEnd: 'End date',
			time: 'Time',
		},
		createModer: {
			title: 'Add a moderator',
			name: 'Name',
			nameWarn: 'Enter a name',
			password: 'Password',
			passwordWarn: 'Enter a password',
			moderatorDescr: `The sections 'Rooms', 'All messages', 'Moderation' are available. Can send messages on behalf of the platform and block users.`,
			adminDescr:
				'All sections of the admin panel are available. Can make changes to the chat appearance, edit blocklists, manage widgets.',
		},
		changePassword: {
			title: 'Change password',
			text: 'Enter a new password',
			newPassword: 'New password',
		},
		roomTalkers: {
			title: 'Participants',
		},
		updateAdmin: {
			title: 'Update a userpic',
		},
		callRecord: {
			title: 'Record a voice chat',
			btns: {
				startRecod: 'Start recording',
				stopRecord: 'Stop recording',
				refresh: 'Reload',
			},
			table: {
				id: 'id',
				link: 'Link',
				status: 'Status',
			},
		},
		addTalker: {
			title: 'Add a participant',
			subtitle: 'They will be able to join a chat with an admitted role.',
			inputPlaceholder: 'User ID',
			roomModer: 'Moderator',
			speaker: 'Speaker',
			btnRole: 'Assign a role',
			assignRoleSubtitle: 'The user will be assigned to this role when entering the room.',
			btnCreateAndRole: 'Create and assign a role',
			noUsers: 'There is no user with this ID',
			noUsersText: `This user hasn't joined any chats yet. We create an account for them, and they will get an admitted role when they enter a chat.`,
		},

		editedMessageHistory: {
			title: 'History',
		},
		badges: {
			title: 'Badges',
			giveBadge: 'Add a badge to user',
			text: 'Find a user by ID',
			placeholder: 'Enter user ID',
		},
	},

	chatAdminForm: {
		name: 'Name',
		message: 'Message',
	},

	badges: {
		userBanned: 'Blocked',
		talkerBanned: 'Blocked in a room',
		speaker: 'Speaker',
	},

	roles: {
		admin: 'Admin',
		moder: 'Moderator',
	},

	autocorrect: {
		addNew: 'Add a new correction',
		from: 'From',
		to: 'To',
	},

	wordlist: {
		version: 'List version',
		addPhrase: 'Add a phrase',
		addWord: 'Add a word',
		addLink: 'Add a link',
		deleteWord: 'Delete word',
		deletePhrase: 'Delete phrase',
		newPhrase: 'New phrase',
		newWord: 'New word',
		newLink: 'New link',
		phraseWarn: 'Enter a new phrase',
		wordWarn: 'Enter a new word',
		linkWarn: 'Enter a new link',
		correctWarn: 'Enter new correction',
		phrase: 'phrase',
		word: 'word',
		alreadyExist: 'This word already exists!',
		failedSave: (word: string) =>
			`Failed to save! You added a word shorter than two characters: ${word}`,
		failedSaveSymbol: (word: string) => `Failed to save! You added an invalid character: ${word}`,
		confirmAddWord: (word: string) => `Do you really want to add: ${word}?`,
		confirmDeleteWord: (word: string) => `Do you really want to delete: ${word}?`,
		blackListWordsAlert: (watchersAppInstance: boolean) =>
			watchersAppInstance
				? 'Only words without spaces.'
				: 'Only words in Cyrillic without extraneous characters and spaces.',
		blackListPhrasesAlert:
			'Words and phrases with spaces and words that contain any extraneous characters, including characters from other alphabets.',
		blackListWordsWarning: (watchersAppInstance: boolean) =>
			watchersAppInstance
				? 'This word contains spaces.'
				: 'This word contains not only Cyrillic characters.',
		blackListPhrasesWarning: 'This word contains only Cyrillic characters and no spaces.',
	},

	submenu: {
		hideMessages: `Hide the user's all messages`,
		showMessages: `Show the user's all messages`,
		showReactions: 'Show message reactions',
		goToRoom: 'Enter to the room',
		hideMessage: 'Hide',
		showMessage: 'Show',
		mode: 'Turn into a moderator',
		unMode: 'Demote',
		mute: 'Mic off',
		unMute: 'Mic on',
		unBan: 'Unblock',
		ban: 'Block',
		hours: 'h',
		days: 'd',
		unBanInRoom: 'Unblock in a room',
		banInRoom: 'Block in a room',
		allUserMessages: `The user's all messages`,
		flagMessage: 'Send to support',
		editMessage: 'Edit message',
		deleteMessage: 'Delete message',
		pinMessage: 'Pin mesasge',
		goToThread: 'Go to thread',
	},
	highlights: {
		create: 'Create',
		edit: 'Edit',
		title: 'Title',
		text: 'Text',
		linkText: 'Button text',
		link: 'Link',
		image: 'Picture',
		iconWeight: (size: number) => `Weight does not exceed ${size}, `,
		iconMinSize: (pngSize: number, svgSize: number) =>
			`minimum PNG size is ${pngSize}×${pngSize} px, SVG is ${svgSize}×${svgSize} px.`,
		name: 'Title',
		status: 'Status',
		rooms: 'Number of rooms',
		views: 'Views',
		actions: 'Actions',
		sendDate: 'Start date',
		planned: 'PLANNED',
		sent: 'SENT',
		templates: 'Templates',
		custom: 'Custom',
		settings: 'Settings',
		learnMore: 'Learn more',
		sendDateTime: 'Sending date/time',
		sendNow: 'Send now',
		chooseDate: 'Choose a date',
		duration: 'Duration',
		durationEndless: 'Display endlessly',
		setDuration: 'Set a duration',
		setEndDate: 'Choose end date',
		endDateTime: 'End date/time',
		manualRoomList: 'Add rooms',
		manual: 'Type or paste',
		downloadRoomList: 'Upload a list',
		confirm: 'Confirm the sending?',
		success: 'Sent successfully',
		roomsError: 'There is an unexisting room on the list',
		noExistingRooms: 'There are no existing rooms in the list',
		history: 'Previous',
		prevStep: 'Previous step',
		nextStep: 'Next step',
		hours: 'Hours',
		minutes: 'Minutes',
		seconds: 'Seconds',
		sec: 'sec',
		specialOffer: 'Special offer',
		specialOfferText:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		specialOfferTextShort:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
		availableRooms: 'Sent to rooms:',
		unavailableRooms: 'Unavailable rooms:',
		goToPrevious: 'Back to previous',
		selectTemplate: 'Select template',
		selectTime: 'Date/time and duration',
		selectRooms: 'Rooms',
		maxRooms: 'Maximum number of rooms: 100',
		downloadFile: 'Download file',
		draft: 'Save draft',
		textType: 'Text',
		imageType: 'Image',
		alignment: 'Alignment',
		bgColor: 'Background color',
		btnColor: 'Button color',
		bgSolid: 'Solid',
		bgGradient: 'Gradient',
		bgGradientStart: 'Start',
		bgGradientEnd: 'End',
		bgGradientDirection: 'Direction',
	},
	offers: {
		template: 'Template',
		content: 'Content',
		settings: 'Publication Settings',
		rooms: 'Audience',
		create: 'Create',
		edit: 'Edit',
		template0: 'Image with Overlay',
		template1: 'Image with Text on a Plaque',
		template2: 'Image Above Text',
		template11: 'Image Only',
		template10: 'Text Only',
		title: 'Title',
		text: 'Text',
		startTime: 'Start Date of Sending',
		endTime: 'End Date of Sending',
		period: {
			title: 'Display Period',
			text: 'Start and End of Sending',
		},
		from: 'From',
		to: 'To',
		sendMessage: 'Send Pop-Up',
		sendMessageDescr:
			'Who will receive the pop-up: all users, participants of specific rooms, or individual users (personal pop-up)?',
		roomList: 'Room List',
		roomListDescr: 'RoomID separated by commas',
		userList: 'User List',
		userListDescr: 'UserID separated by commas',
		fileRoomDescr: 'Upload a CSV file with the list of rooms to send the pop-up, format - UTF-8.',
		fileUserDescr: 'Upload a CSV file with the list of users to send the pop-up, format - UTF-8.',
		radio: {
			allRooms: 'To All Rooms',
			roomList: 'To Rooms by List',
			userList: 'To Individual Users',
		},
		availableRooms: 'Sent to Rooms:',
		unavailableRooms: 'Rooms Unavailable for Sending:',
		availableUsers: 'Sent to Users:',
		unavailableUsers: 'Users Unavailable for Sending:',
		contentSettings: {
			title: 'Pop-up settings',
			picture: 'Picture',
			addTitle: 'Add a headline',
			addText: 'Add text',
			multipleTimes: 'How many times to show the pop-up to users?',
		},
	},

	imageReqs: {
		imageReqs: 'Image requirements',
		imageWeight: (size: number) => `No more than ${size}, `,
		imageMaxSize: ([width, height]: number[]) => `${width}×${height} px`,
		imageMinSize: ([width, height]: number[]) => `at least ${width}×${height} px`,
		imageMinSizeStikers: ([width, height]: number[]) => `at least ${width}×${height}`,
		formats: (formats: string) => `Accepted file types: ${formats}`,
	},

	banReasons: {
		insults: 'Insults, threats, swearing',
		personal: 'Personal data',
		flood: 'Flood',
		links: 'Links, advertising of third-party services',
		violence: 'Violence',
		fraud: 'Fraud and begging',
		nickname: 'Forbidden nickname',
		political: 'Political, sexualized, and other provocations',
		repeated: 'Repeated violation',
		auto: 'Chat user report',
		spam: 'Spam',
	},

	tolokaReasons: {
		insults: 'Insults, threats, swearing',
		personal: 'Personal data',
		flood: 'Flood',
		links: 'Links, advertising of third-party services',
		violence: 'Violence',
		fraud: 'Fraud and begging',
		political: 'Political, sexualized, and other provocations',
		spam: 'Spam',
		suspect: 'Suspect message',
		valid: 'Valid',
	},

	reportsReasons: {
		violence: 'Violence',
		provocations: 'Provocations',
		personalDetails: 'Personal details',
		flood: 'Flood',
		spam: 'Spam',
		fraudBegging: 'Fraud, begging',
		other: 'Other',
	},

	stickers: {
		add: 'Add sticker',
		delete: 'Delete sticker from stickerpack?',
		deleted: 'Sticker deleted',
		added: 'Sticker added',
		fileWarning: (name: string) => `File "${name}" doesn't meet requirements `,
		sticker: 'Sticker',
		stickerRemoved: 'Sticker removed',
		disclaimer:
			'When you upload files (stickers), you confirm that you have all rights (including the right to use images and markers/logos) and comply with applicable laws. Posting obscene content and information that violates the law or the rights of third parties is not allowed.',
		stickerPack: 'Sticker pack',
		newStickerPack: 'New sticker pack',
		uploadNewStickerPack: 'Upload a new sticker pack',
		count: ['sticker', 'stickers', 'stickers'],
		name: 'Stickerpack Name',
	},
	polls: {
		room: 'Room list',
		title: 'Title',
		status: 'Status',
		options: 'Answers',
		creator: 'Creator',
		startTime: 'Publish at',
		createdAt: 'Created at',
		votesLength: 'Voters',
		create: 'Create a poll',
		endTime: 'Finished at',
		finisher: 'Finisher',
		roomList: 'All rooms',
		type: 'Type',
		results: 'Poll results',
		totalAnswers: 'Total answers',
	},
	poll: {
		toggleSubmenu: {
			create: 'Create a poll',
			edit: 'Edit the poll',
			show: 'Show poll',
			end: 'Complete the poll',
			delete: 'Delete the poll',
		},
		tooltip: {
			btn: 'Vote',
		},
		settings: {
			title: 'Settings',
			titleAdditionally: 'More',
			switches: {
				quizMode: 'Quiz mode',
				multipleChoice: 'Multiple choice of answers',
				withPhoto: 'Poll with a picture',
			},
			alert:
				'The quiz mode includes correct and incorrect answers. The minimum number of correct answers is one, the maximum is all. You can`t post a quiz survey without a correct answer selected.',
		},
		room: {
			inputPlaceholder: 'Enter roomID',
		},
		date: 'Sending date/time',
		dateAndDuration: 'Sending date/time and duration',
		autoSendResults: 'Publish results after poll is completed',
		rooms: 'Room IDs',
		question: {
			title: 'Question',
			inputPlaceholder: 'Enter the question',
		},
		message: {
			title: 'You voted',
		},
		answers: {
			title: 'Answer options',
			warning: (count: number) => `You can add up to ${count} answers`,
		},
		answer: {
			tooltip: 'Choose the right answer',
			inputPlaceholder: 'Answer',
		},
		results: {
			text: 'Voting results',
			words: ['vote', 'votes', 'votes'],
		},

		btns: {
			addQuestion: 'Add question',
			addAnswers: 'Add answers',
			addAnswer: 'Add answer',
			publish: 'Publish',
			vote: 'Vote',
			preview: 'Preview',
		},
		supTitles: {
			typeQuiz: 'Choose the right answer',
			typeQuizMultiple: 'Check the correct options',
			typePoll: 'Share your opinion',
			typePollMultiple: 'There are several options',
			default: 'Public poll',
		},
	},
	pollCreating: {
		titles: {
			created: 'Create a poll',
			typeQuiz: 'Quiz',
			typePoll: 'A few answers',
			default: 'Public poll',
		},
		btns: {
			saveDraft: 'Save draft',
		},
		goback: 'Back to polls',
		availableRooms: 'Sent to rooms:',
	},
	settings: {
		slowMode: 'Slowmode',

		greeting: {
			title: 'Placeholder for empty chats',
			description:
				'Add the message that will be shown in empty chats, and don’t forget to translate it for all used languages.',
		},
		chatbot: 'ChatGPT settings',
		enable: 'Enable',
		on: 'On',
		off: 'Off',
		colors: 'Color scheme',
		lang: 'Language',
		icons: 'Icons',
		avatars: 'Avatars',
		reactions: 'Reactions',
		font: 'Font',
		import: 'Import/Export',
		reset: 'Reset settings',
		chatRules: {
			title: 'Chat rules',
			descr:
				'Add detailed chat rules for users, and don’t forget to translate it for all used languages.',
		},
		stickers: 'Stickers',
		currentFont: 'Current font',
		theme: 'theme',
		controls: 'The interface elements',
		headerSettings: {
			title: 'Header settings',
			profileSettings: {
				title: 'Personal settings',
				description:
					'The Settings section is where users can see their avatar and nickname at any moment, set the device for participation in the audio stream, and check the list of blocked users. It opens by clicking on a gear button in the top right corner.',
			},
			userCounter: {
				title: 'User counter',
				description: 'It shows the number of users at a particular moment.',
			},
			addToCalendar: {
				title: 'Add to calendar button',
				description: 'An opportunity for users to add a scheduled room to the calendar.',
			},
			shareButton: {
				title: 'Share Button',
				description: 'An opportunity for users to share a link to the room.',
			},
			exitButton: {
				title: 'Exit button',
				description:
					'An opportunity to close WebView with a button on the chat interface. The ‘Exit’ button is shown in the left top corner. To make this button work, you need to get the event through postMessage.',
			},
			enableScreenSharing: {
				title: 'Share screen button',
				description:
					'Enable this feature if you provide streams in a chat and your speakers plan to share additional material with chat participants. Speakers will be able to share graphs, images, or other visual materials with users. Sharing is possible only through the web version of the service. Users will be able to see shared materials on all platforms.',
				warning: 'Enable Live streams to use Share screen',
			},
			enableOnlySubscriptionsFilter: {
				title: 'Subscriptions button',
				description: 'An opportunity for users to filter messages by subscriptions.',
			},
		},
		showAnimatedEmotions: {
			title: 'Show Animated Reactions',
			description:
				'Toggle this switch to show the option for users to send animated reactions in the chat.',
		},
		userSettings: {
			title: 'Users settings',
			enableNameChange: {
				title: 'Allow users to change a name',
				description: 'Users can set their name through personal settings.',
			},
			enableAvatarChange: {
				title: 'Allow users to change an avatar',
				description: 'Users can set new avatars through personal settings.',
			},
			enableNameVerification: {
				title: 'Checking of transferred names',
				description:
					'If we get users’ names from their main profile, choose if the pre-moderation system should check them.',
			},
			enableProfileDeletion: {
				title: 'Profile deletion',
				description:
					'Showing the ‘Delete profile’ button in users’ personal settings. It allows the deletion of a chat profile but doesn’t affect the main user’s profile on the partner’s service.',
			},
		},
		userProfiles: {
			title: 'User profile',
			allowUserProfile: {
				title: 'Allow to open external profile from chat',
				description: `Users will be able to open each other's profiles from the dropdown menu on a message, or by tapping on an avatar.`,
			},
		},
		firstVisitOptions: {
			title: 'What do users see upon their first entry into a chat?',
			onboarding: {
				title: 'Onboarding',
				description: `Suitable for services that don't transfer names and avatars to chats. Onboarding allows users to create a name and choose an avatar before entering a chat.`,
			},
			chatRulesAccept: {
				title: 'Short chat rules while entering',
				description:
					'A short version of chart rules with the link to the full text. It is shown to users when they enter a chat.',
			},
		},
		copybetting: {
			title: 'Copy-betting widget',
			description: `Turn the widget on to allow users to copy and repeat other users' bets.If you want to use this widget, you need additional setting.`,
			bettingButton: {
				title: 'Enable copy-betting',
			},
		},

		other: {
			title: 'Other',
		},

		enableFullscreenOption: {title: 'Enable fullscreen chat mode while streaming', descr: ''},

		toxicityEstimation: 'Toxicity estimation',
		toxicityEstimationText:
			'Messages will be estimated for toxicity. The estimation is only visible to the moderator.',
		useTolokaAutomoderationToggle: 'Use TAI',
		tolokaAutomoderationWarning: 'There is a fee for using the model. <br> Do you want to start?',
		tolokaAutomoderationWarningText:
			'There is a separate charge for using the TAI model for toxicity assessment and auto-moderation. The service will be invoiced in the month following the reported one.',
		tolokaAutomoderationConfirm: 'Enable the TAI model',
		useDBRTAutomoderationToggle: 'Use DBRT',
		automoderation: 'TAI automoderation',
		autohide: 'Autohide messages',
		autohideText:
			'User messages will be hidden automatically if they were estimated as toxic by the model',
		autohideToggle: 'Automatically hide toxic messages',
		preferredModel: 'Preferred model',
		preferredModelText: 'Model to use for hiding messages',
		tolokaRadio: 'TAI',
		DRBTRadio: 'DRBT',
		loader: 'Preloader',
		policy: 'Privacy Policy',
		agreement: 'License agreement',
		tolokaUsage: 'TAI usage',
		externalPicUrl: 'ImageId url',
		enableUserStatus: 'statusname',
		enableDecrypt: 'decrypt',
		enableRegistrationName: 'nickname',
		enableRegistrationPicId: 'imageId',
		enableRegistrationPicUrl: 'imageUrl',
		defaultTheme: 'Default theme',
		availableLangs: 'Available Interface Languages',
		availableLangsHint: `Select the interface language. This setting determines the language used for the chat. If the 'lang' parameter is specified in the URL, it will override the default language setting.`,
		enableAgora: 'Enable live streams',
		lowerUsersModule: {on: 'Move under the header', off: 'Show fullscreen'},
		showDefaultStickers: {title: 'Show stickers', descr: 'Allow users to send stickers in chat'},
		enableSaveUserIP: 'Enable save user IP',
		enableLinksBlock: 'Block links sending',
		enableMessageTranslation: {
			title: 'Auto-Translation',
			toggle: 'Enable Auto-Translation in a chat',
			descr: 'Turn it on to enable automatic translation of chat messages for users.',
		},
		enableOauth: 'Oauth',
		oauthUrl: 'Oauth url',
		defaultLanguage: {
			title: 'Default interface language',
			descr: `Default language (used if the GET parameter 'lang' is missing)`,
		},
		moderationThresholds: 'Moderation Thresholds',
		showNameToast: {
			title: 'Display Chat Name Toast',
			descr:
				'Toggle this switch to display a toast message "Your chat name is—%chat_name%" whenever entering the chat or moving from room to room.',
		},
		enableDomainWhitelist: {title: 'Enable White-list by domain', descr: ''},
		domainWhitelist: 'Domain whitelist',
		enableMessagePics: {
			title: 'Enable sending of images',
			descr:
				'Enable this feature to allow users to send images in the chat. Once activated, users can upload and send images alongside their text messages.',
		},
		usersPopup: 'Users popup',
		enableThreads: {
			title: 'Threads',
			descr:
				'Enable threaded conversations in chat to allow users to organize discussions more effectively. With this feature, users can start and reply to specific threads within the chat, helping keep related messages grouped together.',
		},
		enableAutobans: {
			title: 'Automatic ban of users',
			descr: `If this feature is active, users can be banned automatically if they are reported by 8 users in one room or by 10 users across different rooms. Moderators can cancel this ban. Banned users receive a notification that they were banned due to other users' complaints.`,
		},
		userModeration: 'User Moderation',
		messaging: 'Messaging',
		roomStates: {
			title: 'Additional states of a room',
			ended: {
				title: 'Text for an ended room',
				text: `This text will be shown to users who try to join an ended room (the room state was switched to 'Ended').`,
			},
			soon: {
				title: 'Text for a room in a "soon" state',
				text: `This text will be shown to users who try to join a room that is planned for the future or temporarily stopped (the room state was switched to 'Soon' or 'Temporarily unavailable').`,
				windowTitle: 'Window title',
				countdownSubtitle: 'Countdown subtitle',
			},
		},
		imageModeration: 'Image Moderation',
		enableMessagePicsModeration: 'Automatically hide inappropriate images',
		preModeration: {
			title: 'Pre-moderation',
			descr:
				'Images will not be displayed in the chat until content moderation is completed. If the image is flagged as inappropriate (e.g., containing NSFW content), it will not be shown, and the user will be notified accordingly.',
		},
		parallelModeration: {
			title: 'Parallel moderation',
			descr:
				'Images are displayed in the chat immediately after being sent, but content moderation is performed simultaneously. If the image is flagged as inappropriate (e.g., NSFW content), it will be hidden from the chat, and the user will be notified.',
		},
		enableWidgetText: {
			title: 'Share Bets and Wins Messaging',
			descr:
				'Toggle this to allow or disable users from adding messages when sharing their bets or wins through the "Share Bet" and "Share Win" widgets.',
		},
		enableContactUsButton: {
			title: '"Contact Us" Button',
			descr:
				'Toggle this switch to control the visibility of the "Contact Us" button in the pop-up shown to users blocked by the administrator.',
		},
	},
	uploadBadges: {
		upload: 'Upload a list',
		loading: 'Loading...',
		badge: 'Badge',
		title: 'Title',
		description: 'Description',
		image: 'Image',
		uploadText: 'Upload a CSV file with the list of users',
		topX: {
			title: 'Badges TOP-X',
			description: 'Users will be assigned badges based on their position on the list.',
			text: 'Upload a CSV file with the list of userID.',
		},
		customBadges: {
			title: 'Custom badge',
			description:
				'Create a new badge. Customize its name, color, description and pictures for notification.',
		},
		downloadExample: 'Download example',
		create: {
			title: 'Create a badge',
			settings: 'Settings',
			users: 'Users',
			badge: {
				name: 'Badge name',
				description: 'Better use not more than 10-12, and also use emoji.',
			},
			notification: {
				title: 'Notification when assigning a badge',
				description:
					'The message that the user will see when entering the chat when he has been assigned this badge',
				toggle: 'Show notification',
			},
			tooltip: {
				title: 'Tooltip',
				description: 'Short description for badge',
			},
			file: {
				title: 'Upload a list',
				text: 'Upload a CSV file with the list of users',
			},
		},
		edit: 'Edit badge',
		usersEnding: ['user', 'users', 'users'],
		backgroundColor: 'Background color',
		textColor: 'Text color',
		assignedBadges: 'Bagdes',
		giveBadge: 'Assign a badge',
	},
	flaggedMessage: {
		reject: 'Reject',
		rejected: 'Rejected',
		considered: 'Considered',
	},
	profileSettings: {
		profile: 'Profile',
		title: 'Profile settings',
		access: 'Access to the admin panel',
		login: 'Login',
		password: 'Password',
		changePassword: 'Change password',
		userProfile: 'User profile',
		userProfileDescr: 'Avatar and nickname shown in chats',
		userName: 'Nickname',
		uploadAvatar: 'Upload a picture',
		currentPassword: 'Current password',
		newPassword: 'New password',
		passwordWarn: 'Wrong password',
	},

	export: {
		title: 'Export data',
		type: 'Type',
		from: 'From',
		to: 'To',
		status: 'Status',
		users: 'Users',
		messages: 'Messages',
		ready: 'Ready',
		forming: 'In process...',
		new: 'New',
		hint: 'The generated file will contain the time GMT +0',
		modal: {
			title: 'Export data',
			descr: 'Data for export',
			period: 'Period',
			btnExport: 'Export',
		},
	},

	semantic: {
		new: 'New analysis',
		analysis: 'Analysis',
		model: 'Model',
		percent: '% completion',
		created: 'Created at',
		status: 'Status',
		settings: 'Settings',
		prompt: 'Prompt',
		promptDescr: 'Instructions for the AI, describing the task for analysis.',
		data: 'Data for analysis',
		dataDescr:
			'Load a CSV file with data for semantic analysis. The file must contain only the "text" column, do not contain empty lines, the text format is UTF-8.',
		top100: 'Top 100 used words',
		test: 'Test',
		start: 'Start',
		stop: 'Stop',
		startAgain: 'Start again',
		historySave: 'Save to history',
		promptHistory: 'Prompt history',
		savedPrompts: 'Saved prompts',
		downloadResults: 'Download results',
		analysisStarted: 'Analysis started...',
		analysisFinished: 'Analysis finished',
		words: 'words',
		promptStatus: {
			inProgress: 'In progress...',
			finished: 'Finished',
			uploaded: 'Finished',
			draft: 'Draft',
			error: 'Error',
		},
	},

	projects: {
		name: 'Project name',
		created: 'Project created',
		new: 'New project',
		settings: 'Settings',
		removeProject: 'Remove the project?',
		btn: {
			new: 'New',
		},
	},
	welcome: {
		title: 'Greeting pop-up',
		createTitle: 'Create a greeting pop-up',
		creating: 'Create',
		warning: 'Add a headline and text',
		warningLink: 'Check the link',
		draft: 'Draft',
		configure: {
			title: 'Open a chat window',
			text: 'A greeting message users see when join a chat',
		},
		placeholder: {
			title: 'Headline',
			text: 'A text message in a chat window',
		},
		btns: {
			configure: 'Set',
			edit: 'Edit',
			publish: 'Publish',
			saveDraft: 'Save as a draft',
		},
		toggle: {
			on: 'Turn on',
		},
		steps: {
			content: {
				title: 'A message body',
				welcomeTitle: 'A chat window headline',
				welcomeText: 'Text',
				btn: 'Button',
				btnText: 'Button text',
				link: 'Link',
			},
			showEntry: {
				title: 'Display rate',
				showRepeat: 'Recurring display',
				showRepeatDescr: 'Number of displaying',
				showOnce: 'Once',
				showTimes: 'Several times',
			},
			frequency: {
				title: 'Frequency of displaying',
				descr: 'How often is the greeting message displayed?',
				daily: 'Daily',
				onEntry: 'Every time a user joins a chat',
			},
			moment: {
				title: 'Moment of display',
				descr: 'When is the greeting message displayed?',
				onEntry: 'Immediately upon joining',
				withDelay: 'With a delay',
				showDelay: 'Delay before displaying',
			},
			btns: {
				next: 'Next step',
				prev: 'Previous step',
				publish: 'Publish',
				saveDraft: 'Save as a draft',
			},
		},
		delete: {
			title: 'Delete',
			text: 'Do you really want to delete a greeting message?',
		},
	},
	badConnection: 'This user has connection problems',
	message: {
		hidden: 'Hidden',
		edited: 'Edited',
	},
	analytics: {
		updateRequestSent: 'Update request sent',
	},
	autoposting: {
		badge: 'Autopublication',
		toggle: 'Hide autopublication',
		title: 'Autoposting of messages',
		descr: 'Posting messages to chats automatically to retain users`activity.',
		toggleLabel: 'To publish messages automatically',
		warning: `Automated messages will be hidden in the 'All Messages' feed by default. You can unhide them by toggling the switch under the gear icon in the top right corner of the feed.`,
		settings: {
			title: 'Autoposting settings',
			tabTitle: 'Settings',
			descr: 'Choose chats where messages will be posted automatically',
			radio: {
				empty: 'Empty chats',
				notActive: 'Non-active chats',
			},
			selectTitle: 'To include non-active chats:',
		},
		delay: {
			title: 'Delay before publishing',
			descr: 'What time should be waited between the first users` entry and autopublication?',
		},
		messagesList: {
			title: 'List of messages',
			descr: 'What list will be used for autopublication?',
		},
		defaultList: 'Default',
		customList: 'Custom',
		addLang: 'Add localisation',
		fromFile: {
			title: 'Upload a file with messages',
			descr: 'Upload a CSV file with messages for autopublication.',
			text: 'File has to include two columns according to languages.',
			warn: 'Please add the 2-letter ISO code for used languages to the first line (ex., EN, ES, HI).',
		},
		fileAlert: {
			title: 'Replace with messages from the file',
			text: 'Are you sure?',
			btns: {
				replace: 'Replace',
				cancel: 'Cancel',
			},
		},
		deleteMessagesAlert: {
			title: 'Delete all messages?',
			text: 'Are you sure? You won`t be able to restore them.',
			btns: {
				delete: 'Delete',
				cancel: 'Cancel',
			},
		},
	},
	webhooks: {
		enable: 'Enable',
		getEvents: 'All events',
		errorAlertTitle: 'Failed to send events',
		errorAlertText:
			'The last 5 requests were unsuccessful. Check the settings and re-enable sending.',
		rooms: 'Rooms',
	},

	responseStatus: {
		success: 'Success',
		successText: 'Test request sent successfully',
		error: 'Error',
		errorText: 'Test request received an error response',
	},

	languages: {
		ru: 'Russian',
		en: 'English',
		hy: 'Armenian',
		sr: 'Serbian',
		de: 'German',
		es: 'Spanish',
		ar: 'Arabic',
		ro: 'Romanian',
		tr: 'Turkish',
		hi: 'Hindi',
		pt: 'Portuguese',
		zh: 'Chinese',
		ms: 'Malay',
		bn: 'Bengali',
		th: 'Thai',
		fr: 'French',
		fi: 'Finnish',
		is: 'Icelandic',
		ko: 'Korean',
		pl: 'Polish',
		az: 'Azerbaijani',
		uz: 'Uzbek',
		uk: 'Ukrainian',
		ja: 'Japanese',
	},

	liveStream: {
		settings: 'Live stream settings',
		autorecording: {
			title: 'Auto Recording',
			text: 'The Auto Recording feature automatically captures all live streams, making recordings available for listening or download on the live stream room page in the back office. This ensures content is never missed and enhances viewer engagement by providing on-demand access to past streams.',
		},
		qualitySurvey: {
			title: 'Stream Quality Survey',
			text: 'The Stream Quality Survey feature prompts users with a brief questionnaire to gauge their live stream listening experience. This feedback tool is designed to enhance stream quality by directly incorporating viewer insights, ensuring a continuously improved and satisfying user experience.',
		},
		globalStreamAccess: {
			title: 'Global Audio Stream Access',
			text: 'The Global Audio Stream Access allows administrators to enable audio streams across all rooms by default. This setting ensures that live audio streams is readily available in every room, providing a seamless and unified listening experience for all users without the need for individual room configuration.',
		},
	},
	gambles: {
		enableWinPublication: {
			title: 'Enable Win Publication',
			description:
				'When enabled, the system will automatically publish widgets based on the data provided in the API and the configured settings.',
		},
		gambleIntervalMS: {
			title: 'Time interval, minutes',
			description:
				'Set the time interval (in minutes) between automatic widget publications. This allows you to control the frequency of widget appearances in the chat based on your configured settings.',
		},
		enableGambleConditions: {
			title: 'Enable widget publication by condition',
			description:
				'Prevents automatic widget publication if the last message in the chat is already a widget.',
		},
	},
	learnMore: 'Learn more',
	images: {
		hideImages: 'Hide images',
	},
	pinnedMessage: 'Pinned Message',
	reactions: {
		title: 'Message reactions',
		noReations: 'No reactions',
	},
	toggle: {
		on: 'Turn on',
	},
	subscribe: {
		email: 'Email to recive updates',
	},
	changelog: {
		category: 'Category',
		version: 'Version',
		description: 'Description',
	},
	linkOpenType: {
		title: 'Link open type',
		currentWindow: 'Open in the current window',
		newWindow: 'Open in a new window',
		postMessage: 'Send post message',
	},
} as unknown as Dictionary;

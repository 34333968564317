/* eslint-disable max-lines */
import React, {useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {NavLink, useHistory} from 'react-router-dom';
import {Nav, Navbar, Stack, Button, Image, NavDropdown, Badge, Accordion} from 'react-bootstrap';
import {Scrollbars} from 'react-custom-scrollbars-2';

import classNames from 'classnames';

import './sidebar.scss';
import userServices from 'store/userServices';
import reportServices from 'store/reportServices';
import appService from 'store/appService';
import banServices from 'store/banServices';

import useL10n from 'l10n/useL10n';
import LanguageTag from 'models/enums/LanguageTag.enum';

import SelectProject from 'components/selectProject/selectProject';

import {ReactComponent as IcoMessages} from 'assets/svg/ico-messages.svg';
import {ReactComponent as IcoRooms} from 'assets/svg/ico-rooms.svg';
import {ReactComponent as IcoModer} from 'assets/svg/ico-moderation.svg';
import {ReactComponent as IcoManage} from 'assets/svg/ico-manage.svg';
import {ReactComponent as IcoPremoder} from 'assets/svg/ico-premoderation.svg';
import {ReactComponent as IcoWidget} from 'assets/svg/ico-widget.svg';
import {ReactComponent as IcoReport} from 'assets/svg/ico-reports.svg';
import {ReactComponent as IcoSettings} from 'assets/svg/ico-settings.svg';
import {ReactComponent as IcoWrap} from 'assets/svg/ico-wrap.svg';
import {ReactComponent as IcoLang} from 'assets/svg/ico-lang.svg';
import {ReactComponent as IcoReporting} from 'assets/svg/ico-reporting.svg';
import {ReactComponent as IcoThemeDark} from 'assets/svg/ico-theme-dark.svg';
import {ReactComponent as IcoThemeLight} from 'assets/svg/ico-theme-light.svg';
import {ReactComponent as IcoThemeSystem} from 'assets/svg/ico-theme-system.svg';
import {ReactComponent as IcoInfo} from 'assets/svg/ico-info.svg';

import SelectControl from 'components/select/SelectControl';
import flaggedMessageService from 'store/flaggedMessageService';
import {Theme} from 'models/enums/Theme.enum';
import {EN_DOC_URL, RU_DOC_URL} from 'constants/constants';
import changelogService from 'store/changelogService';

interface ISidebarProps {
	toggleMenu: () => void;
}

const Sidebar: React.FC<ISidebarProps> = function Sidebar(props) {
	const {toggleMenu} = props;
	const history = useHistory();
	const translations = useL10n();

	const {isAdmin, isSuperAdmin, isCreator, clearUserData, projectUsers, admin} = useLocalObservable(
		() => userServices
	);
	const [isMenuOpen, setMenuOpen] = useState(true);

	const {reportsCount} = useLocalObservable(() => reportServices);
	const {autoBansCount} = useLocalObservable(() => banServices);
	const {flaggedMessageCount} = useLocalObservable(() => flaggedMessageService);

	const {
		language,
		setLanguage,
		userLocale,
		projectId,
		setProjectId,
		showHiddenSettings,
		appThemeMode,
		setAppThemeMode,
	} = useLocalObservable(() => appService);
	const {unviewedChangelogCount} = useLocalObservable(() => changelogService);

	const isDefaultProject = projectId === 'default';

	const [selectedLanguage, setSelectedLanguage] = useState({value: language, label: language});
	const [activeKey, setActiveKey] = useState('0');

	const languageOptions = [
		{value: LanguageTag.ru, label: LanguageTag.ru},
		{value: LanguageTag.en, label: LanguageTag.en},
	];

	const watchersAppInstance = process.env.REACT_APP_INSTANCE === 'watchers';

	const onClickHandler = () => {
		clearUserData();
		setProjectId('');
		localStorage.removeItem('accessToken');
		localStorage.removeItem('projectId');
		localStorage.removeItem('projectUsers');
		localStorage.removeItem('user');
		localStorage.removeItem('chatUser');
		localStorage.getItem('isSuperAdmin') && localStorage.removeItem('isSuperAdmin');
		history.push('/login');
	};

	const menuOpenStyles = classNames('sidebar', {
		'sidebar--closed': !isMenuOpen,
	});

	const setThemeBtnClick = (theme: Theme) => {
		setAppThemeMode(theme);
		localStorage.setItem('watchersAdminTheme', theme);
	};

	const onCloseMenuHandler = () => {
		toggleMenu();
		setMenuOpen(!isMenuOpen);
		setActiveKey('0');
	};

	const onLanguageHandler = (selectedOption: any) => {
		setSelectedLanguage(selectedOption);
		setLanguage(selectedOption.value);
		localStorage.setItem('watcherswebLanguage', selectedOption.value);
	};

	useEffect(() => {
		setSelectedLanguage({value: language, label: language});
	}, [language]);

	return (
		<aside className={menuOpenStyles}>
			<Navbar>
				<div className='sidebar__wrapper'>
					<Stack gap={3}>
						<div className='sidebar__header'>
							{isMenuOpen && projectUsers.length > 1 && (
								<div className='sidebar__select'>
									<SelectProject projectUsers={projectUsers} sidebar />
								</div>
							)}

							{isMenuOpen && projectUsers.length === 1 && (
								<div className='sidebar__project'>{projectId}</div>
							)}

							<div className='sidebar__controls'>
								<button className='sidebar__wrap' onClick={onCloseMenuHandler} type='button'>
									<IcoWrap className='sidebar__wrap-icon' />
								</button>

								{(userLocale === 'ru' || userLocale === 'ru-RU') && (
									<div className='sidebar__lang'>
										<IcoLang className='sidebar__lang-icon' />
										<SelectControl
											handleChange={onLanguageHandler}
											options={languageOptions}
											value={selectedLanguage}
											theme='dark'
										/>
									</div>
								)}
							</div>
						</div>

						<Nav className='h-100 text-primary flex-column'>
							<Scrollbars>
								<Accordion activeKey={activeKey} onSelect={(e: any) => setActiveKey(e)}>
									{!isDefaultProject && (
										<Accordion.Item eventKey='0'>
											<div className='sidebar__accordion'>
												<Nav.Item>
													<NavLink
														to='/last-messages'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<IcoMessages className='sidebar__icon' />
														<span>{translations.sidebar.lastMessages}</span>
													</NavLink>
												</Nav.Item>
											</div>
										</Accordion.Item>
									)}

									{!isDefaultProject && (
										<Accordion.Item eventKey='1'>
											<div className='sidebar__accordion'>
												<Accordion.Header as='div'>
													<Nav.Item>
														<div className='sidebar__link'>
															<IcoRooms className='sidebar__icon' />
															<span>{translations.sidebar.rooms.title}</span>
														</div>
													</Nav.Item>
												</Accordion.Header>
											</div>

											<Accordion.Body>
												<Nav.Item>
													<NavLink
														to='/rooms'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.allRooms}</span>
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/precreated'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.createdRooms.title}</span>
													</NavLink>
												</Nav.Item>
											</Accordion.Body>
										</Accordion.Item>
									)}

									{!isDefaultProject && (
										<Accordion.Item eventKey='2'>
											<div className='sidebar__accordion'>
												<Accordion.Header as='div'>
													<Nav.Item>
														<div className='sidebar__link'>
															<IcoModer className='sidebar__icon' />
															<span>{translations.sidebar.moderation}</span>
															{reportsCount > 0 && activeKey !== '2' && (
																<Badge pill bg='primary' className='sidebar__badge'>
																	{reportsCount}
																</Badge>
															)}
														</div>
													</Nav.Item>
												</Accordion.Header>
											</div>
											<Accordion.Body>
												<Nav.Item>
													<NavLink
														to='/reports'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.reports.title}</span>
														{reportsCount > 0 && activeKey === '2' && (
															<Badge pill bg='primary' className='sidebar__badge'>
																{reportsCount}
															</Badge>
														)}
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/reports-archive'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.archivedReports.title}</span>
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/banned'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.bans.title}</span>
														{autoBansCount > 0 && (
															<Badge pill bg='primary'>
																{autoBansCount}
															</Badge>
														)}
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/auto-banned'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.autoBanned.title}</span>
														{autoBansCount > 0 && (
															<Badge pill bg='primary'>
																{autoBansCount}
															</Badge>
														)}
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/bans-history'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.banHistory.title}</span>
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/images'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.images.title}</span>
													</NavLink>
												</Nav.Item>
												{isAdmin && (
													<Nav.Item>
														<NavLink
															to='/automoderation'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.automoderation}</span>
														</NavLink>
													</Nav.Item>
												)}
											</Accordion.Body>
										</Accordion.Item>
									)}

									{isAdmin && !isDefaultProject && (
										<Accordion.Item eventKey='3'>
											<div className='sidebar__accordion'>
												<Accordion.Header as='div'>
													<Nav.Item>
														<div className='sidebar__link'>
															<IcoPremoder className='sidebar__icon' />
															<span>{translations.sidebar.wordList}</span>
														</div>
													</Nav.Item>
												</Accordion.Header>
											</div>
											<Accordion.Body>
												<Nav.Item>
													<NavLink
														to='/blacklist'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.blackList.title}</span>
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/phraselist'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.blackPhraseList.title}</span>
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/whitelist'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.whiteList.title}</span>
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/sitelist'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.siteList.title}</span>
													</NavLink>
												</Nav.Item>

												{isSuperAdmin && (
													<Nav.Item>
														<NavLink
															to='/default-lists'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.defaultLists}</span>
														</NavLink>
													</Nav.Item>
												)}
											</Accordion.Body>
										</Accordion.Item>
									)}

									{!isDefaultProject && (
										<Accordion.Item eventKey='4'>
											<div className='sidebar__accordion'>
												<Accordion.Header as='div'>
													<Nav.Item>
														<div className='sidebar__link'>
															<IcoWidget className='sidebar__icon' />
															<span>{translations.sidebar.widgets}</span>
														</div>
													</Nav.Item>
												</Accordion.Header>
											</div>
											<Accordion.Body>
												{isAdmin && (
													<>
														<Nav.Item>
															<NavLink
																to='/highlights'
																activeClassName='activeRoute'
																className='sidebar__link'>
																<span>{translations.sidebar.highlights.title}</span>
															</NavLink>
														</Nav.Item>
														<Nav.Item>
															<NavLink
																to='/offers'
																activeClassName='activeRoute'
																className='sidebar__link'>
																<span>{translations.sidebar.offers.title}</span>
															</NavLink>
														</Nav.Item>
													</>
												)}
												<Nav.Item>
													<NavLink
														to='/polls'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.pollsQuizzes.title}</span>
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/welcome'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.welcome}</span>
													</NavLink>
												</Nav.Item>
												{isAdmin && (
													<>
														<Nav.Item>
															<NavLink
																to='/badges'
																activeClassName='activeRoute'
																className='sidebar__link'>
																<span>{translations.sidebar.badges.title}</span>
															</NavLink>
														</Nav.Item>
														<Nav.Item>
															<NavLink
																to='/gambles'
																activeClassName='activeRoute'
																className='sidebar__link'>
																<span>{translations.sidebar.gambles.title}</span>
															</NavLink>
														</Nav.Item>
													</>
												)}
											</Accordion.Body>
										</Accordion.Item>
									)}

									{!isDefaultProject && (
										<Accordion.Item eventKey='8'>
											<div className='sidebar__accordion'>
												<Accordion.Header as='div'>
													<Nav.Item>
														<div className='sidebar__link'>
															<IcoReport className='sidebar__icon' />
															<span>{translations.sidebar.feedback}</span>
															{flaggedMessageCount > 0 && activeKey !== '8' && (
																<Badge pill bg='primary' className='sidebar__badge'>
																	{flaggedMessageCount}
																</Badge>
															)}
														</div>
													</Nav.Item>
												</Accordion.Header>
											</div>
											<Accordion.Body>
												<Nav.Item>
													<NavLink
														to='/flaggedMessage'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.flaggedMessage.title}</span>
														{flaggedMessageCount > 0 && activeKey === '8' && (
															<Badge pill bg='primary' className='sidebar__badge'>
																{flaggedMessageCount}
															</Badge>
														)}
													</NavLink>
												</Nav.Item>
												{isAdmin && (
													<Nav.Item>
														<NavLink
															to='/feedback'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.soundQuality.title}</span>
														</NavLink>
													</Nav.Item>
												)}
											</Accordion.Body>
										</Accordion.Item>
									)}

									{isAdmin && !isDefaultProject && (
										<Accordion.Item eventKey='12'>
											<div className='sidebar__accordion'>
												<Accordion.Header as='div'>
													<Nav.Item>
														<div className='sidebar__link'>
															<IcoReporting className='sidebar__icon' />
															<span>{translations.sidebar.reporting}</span>
														</div>
													</Nav.Item>
												</Accordion.Header>
											</div>
											<Accordion.Body>
												<Nav.Item>
													<NavLink
														to='/export'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.export.title}</span>
													</NavLink>
												</Nav.Item>
												{isSuperAdmin && (
													<Nav.Item>
														<NavLink
															to='/semantic'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.semantic}</span>
														</NavLink>
													</Nav.Item>
												)}
											</Accordion.Body>
										</Accordion.Item>
									)}

									{isAdmin && !isDefaultProject && (
										<Accordion.Item eventKey='6'>
											<div className='sidebar__accordion'>
												<Accordion.Header as='div'>
													<Nav.Item>
														<div className='sidebar__link'>
															<IcoManage className='sidebar__icon' />
															<span>{translations.sidebar.admin}</span>
														</div>
													</Nav.Item>
												</Accordion.Header>
											</div>
											<Accordion.Body>
												{/* <Nav.Item>
													<NavLink
														to='/moders'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.roomModers}</span>
													</NavLink>
												</Nav.Item> */}
												<Nav.Item>
													<NavLink
														to='/admin'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.admins.title}</span>
													</NavLink>
												</Nav.Item>
											</Accordion.Body>
										</Accordion.Item>
									)}

									<Accordion.Item eventKey='7'>
										<div className='sidebar__accordion'>
											<Accordion.Header as='div'>
												<Nav.Item>
													<div className='sidebar__link'>
														<IcoSettings className='sidebar__icon' />
														<span>{translations.sidebar.settings}</span>
													</div>
												</Nav.Item>
											</Accordion.Header>
										</div>
										<Accordion.Body>
											{isAdmin && (
												<>
													<Nav.Item>
														<NavLink
															to='/appearance'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.appearance.title}</span>
														</NavLink>
													</Nav.Item>
													<Nav.Item>
														<NavLink
															to='/language'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.language.title}</span>
														</NavLink>
													</Nav.Item>
													<Nav.Item>
														<NavLink
															to='/slowmode'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.slowMode.title}</span>
														</NavLink>
													</Nav.Item>
													<Nav.Item>
														<NavLink
															to='/chatbot'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.chatGPT.title}</span>
														</NavLink>
													</Nav.Item>
												</>
											)}

											<Nav.Item>
												<NavLink
													to='/autoposting'
													activeClassName='activeRoute'
													className='sidebar__link'>
													<span>{translations.sidebar.autoposting}</span>
												</NavLink>
											</Nav.Item>

											{isAdmin && (
												<>
													<Nav.Item>
														<NavLink
															to='/email'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.email.title}</span>
														</NavLink>
													</Nav.Item>
													<Nav.Item>
														<NavLink
															to='/functionalSettings'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.functionalSettings}</span>
														</NavLink>
													</Nav.Item>
												</>
											)}
											{(projectId === 'dev' ||
												projectId.includes('-dev') ||
												projectId.includes('-stage')) &&
												isAdmin && (
													<Nav.Item>
														<NavLink
															to='/s3provider'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>S3Provider</span>
														</NavLink>
													</Nav.Item>
												)}
											{isSuperAdmin && showHiddenSettings && (
												<Nav.Item>
													<NavLink
														to='/amplitude'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.amplitude}</span>
													</NavLink>
												</Nav.Item>
											)}
											{isAdmin && !isDefaultProject && (
												<Nav.Item>
													<NavLink
														to='/apikey'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>APIKey</span>
													</NavLink>
												</Nav.Item>
											)}
											{isAdmin && !isDefaultProject && (
												<Nav.Item>
													<NavLink
														to='/bearer'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>Bearer tokens</span>
													</NavLink>
												</Nav.Item>
											)}
											{isAdmin && (
												<>
													<Nav.Item>
														<NavLink
															to='/webhooks'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>Webhooks</span>
														</NavLink>
													</Nav.Item>

													<Nav.Item>
														<NavLink
															to='/liveStreams'
															activeClassName='activeRoute'
															className='sidebar__link'>
															<span>{translations.sidebar.liveStreams}</span>
														</NavLink>
													</Nav.Item>
												</>
											)}
										</Accordion.Body>
									</Accordion.Item>

									{isCreator && !isDefaultProject && (
										<Accordion.Item eventKey='10'>
											<div className='sidebar__accordion'>
												<Accordion.Header as='div'>
													<Nav.Item>
														<div className='sidebar__link'>
															<IcoManage className='sidebar__icon' />
															<span>{translations.sidebar.projects}</span>
														</div>
													</Nav.Item>
												</Accordion.Header>
											</div>
											<Accordion.Body>
												<Nav.Item>
													<NavLink
														to='/projects'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.createProject}</span>
													</NavLink>
												</Nav.Item>
												<Nav.Item>
													<NavLink
														to='/projectList'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<span>{translations.sidebar.projectsList}</span>
													</NavLink>
												</Nav.Item>
											</Accordion.Body>
										</Accordion.Item>
									)}
									{isAdmin && !isDefaultProject && (
										<Accordion.Item eventKey='11'>
											<div className='sidebar__accordion'>
												<Nav.Item>
													<NavLink
														to='/analytics'
														activeClassName='activeRoute'
														className='sidebar__link'>
														<IcoRooms className='sidebar__icon' />
														<span>{translations.sidebar.analytics}</span>
													</NavLink>
												</Nav.Item>
											</div>
										</Accordion.Item>
									)}
									<Accordion.Item eventKey='13'>
										<div className='sidebar__accordion'>
											<Accordion.Header as='div'>
												<Nav.Item>
													<div className='sidebar__link'>
														<IcoInfo className='sidebar__icon' />
														<span>{translations.sidebar.information.title}</span>
														{unviewedChangelogCount > 0 && activeKey !== '13' && (
															<Badge pill bg='primary' className='sidebar__badge'>
																{unviewedChangelogCount}
															</Badge>
														)}
													</div>
												</Nav.Item>
											</Accordion.Header>
										</div>
										<Accordion.Body>
											<Nav.Item>
												<NavLink
													to='/changelog'
													activeClassName='activeRoute'
													className='sidebar__link'>
													<span>{translations.sidebar.changelog.title}</span>

													{unviewedChangelogCount > 0 && activeKey === '13' && (
														<Badge pill bg='primary' className='sidebar__badge'>
															{unviewedChangelogCount}
														</Badge>
													)}
												</NavLink>
											</Nav.Item>
											<Nav.Item>
												<a
													href={watchersAppInstance ? EN_DOC_URL : RU_DOC_URL}
													target='_blank'
													rel='noreferrer'
													className='sidebar__link'>
													<span>{translations.sidebar.documentation}</span>
												</a>
											</Nav.Item>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</Scrollbars>

							{admin && (
								<Nav.Item className='mt-auto'>
									<div className='sidebar__account'>
										{admin.chatPic && (
											<Image src={admin.chatPic} roundedCircle className='sidebar__account-pic' />
										)}

										{isMenuOpen && (
											<div className='w-100 d-flex align-items-center justify-content-between'>
												<NavDropdown
													id='nav-profile-settings'
													title={admin.chatName}
													menuVariant='dark'
													drop='up'
													className='sidebar__user-dropup w-100'>
													<div className='sidebar__user-dropdown-menu'>
														<div className='sidebar__user-dropdown-header'>
															<Button
																variant='link'
																size='sm'
																active={appThemeMode === Theme.LIGHT}
																className='sidebar__theme-btn'
																onClick={() => setThemeBtnClick(Theme.LIGHT)}>
																<IcoThemeLight className='sidebar__theme-btn-ico-light' />
															</Button>
															<Button
																variant='link'
																size='sm'
																active={appThemeMode === Theme.SYSTEM}
																className='sidebar__theme-btn'
																onClick={() => setThemeBtnClick(Theme.SYSTEM)}>
																<IcoThemeSystem className='sidebar__theme-btn-ico-light' />
															</Button>
															<Button
																variant='link'
																size='sm'
																active={appThemeMode === Theme.DARK}
																className='sidebar__theme-btn'
																onClick={() => setThemeBtnClick(Theme.DARK)}>
																<IcoThemeDark className='sidebar__theme-btn-ico-dark' />
															</Button>
														</div>
														<NavDropdown.Item as='div' className='p-1 px-4'>
															<NavLink to='/profileSettings' className='p-0'>
																{translations.sidebar.profileSettings}
															</NavLink>
														</NavDropdown.Item>
														<NavDropdown.Item
															as='div'
															className='p-2 px-4'
															onClick={onClickHandler}>
															{translations.sidebar.logout}
														</NavDropdown.Item>
													</div>
												</NavDropdown>
											</div>
										)}
									</div>
								</Nav.Item>
							)}
						</Nav>
					</Stack>
				</div>
			</Navbar>
		</aside>
	);
};
export default observer(Sidebar);
